@mixin flex-end {
  display: flex;
  justify-content: flex-end;
}

@mixin centering-mode {
  display: flex;
  justify-content: center;
  align-items: center;
}
