@import 'variables';
@import 'mixin';

.intro {
  &__wrap {
    display: flex;
    margin-bottom: 183px;
  }

  &__title {
    margin: 130px 0 60px 0;
  }

  .left-block {
    max-width: 1125px;
    width: 100%;

    &__text {
      line-height: 28.8px;
    }
  }

  .right-block {
    max-width: 554px;
    width: 100%;
    flex-shrink: 0;
  }

  .chat {
    position: relative;
    font-size: 14px;
    line-height: 21px;

    &__img-wrap {
      position: absolute;
      z-index: -1;

      bottom: -261px;
      left: 97px;

      img {
        width: 100%;
        min-width: 220px;
        height: 100%;
      }
    }

    &__message {
      max-width: 267px;
      width: 100%;

      border-radius: 30px;
      background: linear-gradient(-89.79deg, #c0e8ff 0.17%, #dcf4ff 99.82%);

      [data-theme=dark] & {
        background: linear-gradient(-89.79deg, #183b64 0.17%, #12304b 99.82%);
      }

      padding: 37px 50px 32px;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
        padding: 45px 50px;
      }

      &_triangle {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          right: -40px;
          bottom: -13px;

          border: 19px solid transparent;
          border-left: 41px solid #c1e8ff;

          [data-theme=dark] & {
            border-left: 41px solid #183b64;
          }

          transform: rotate(23deg);
        }
      }
    }

    p {
      max-width: 167px;
      width: 100%;
      margin: 0;
    }
  }
}

.order-block {
  position: relative;
  background: linear-gradient(89.79deg, #c0e8ff 0.17%, #dcf4ff 99.82%);

  [data-theme=dark] & {
    background: linear-gradient(89.79deg, #183c649c 0.17%, #12304b8a 99.82%);
  }

  padding: 155px 0;

  &__img-wrap {
    z-index: 4;
    position: absolute;
    top: -221px;
    left: 80px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__cnt {
    margin-right: 30px;
  }

  &__title {
    margin-bottom: 50px;
  }

  &__btn-wrap {
    max-width: 237px;
    width: 100%;
  }
}

.course {
  background-image: linear-gradient(89.79deg, #c0e8ff 0.17%, #dcf4ff 99.82%);

  [data-theme=dark] & {
    background-image: linear-gradient(89.79deg, rgb(0 75 118 / 45%) 0.17%, rgba(27, 61, 114, 0.2) 99.82%);
  }

  &__container {
    max-width: 1392px;
  }

  &__title {
    text-align: center;
    margin-bottom: 100px;
  }

  &__block {
    margin-bottom: 80px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .block {
    display: flex;
    justify-content: space-between;

    &__img-wrap {
      margin-right: 40px;
    }

    &__list-wrap {
      margin: 32px 0 0;
    }

    &__title {
      margin-bottom: 50px;
    }
  }

  .list-wrap {
    max-width: 600px;
    width: 100%;
  }

  .list {
    li {
      position: relative;
      padding-left: 50px;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;

        width: 10px;
        height: 10px;

        border-radius: 50%;
        background-color: $bg-col-pacificBlue;
      }
    }
  }
}

.study {
  &__title {
    text-align: center;
    margin-bottom: 150px;
  }

  &__btn-wrap {
    display: flex;
    justify-content: center;
  }

  .about-study {
    position: relative;
    display: flex;
    justify-content: space-between;

    margin-bottom: 140px;

    &__desc-wrap {
      max-width: 630px;
      width: 100%;

      P {
        position: relative;
        padding-left: 106px;
        margin-bottom: 90px;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;

          width: 55px;
          height: 40px;

          background-image: url('../images/main-page/study/vector.svg');
        }
      }

      &:first-child {
        margin-right: 240px;
      }
    }

    &__img-wrap {
      position: absolute;
      top: 45%;
      left: 48.7%;
      transform: translate(-50%, -50%) rotate(353deg);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.after-course {
  &__title {
    text-align: center;
    margin-bottom: 102px;
  }

  .steps-wrap {
    position: relative;
    margin-bottom: 86px;

    .steps-t {
      display: flex;
      justify-content: space-between;

      max-width: 1547px;
      width: 100%;
      margin: 0 auto 30px;

      p {
        margin-top: 0;
        text-align: center;
        width: 205px;
      }
    }

    .steps-b {
      @include centering-mode;

      max-width: 1547px;
      width: 100%;
      margin: 0 auto;
      padding: 0 80px;

      .step {
        &__round {
          display: block;
          width: 45px;
          height: 45px;

          border-radius: 50%;
          border: 6px solid $bg-col-pattensBlue;
        }

        &__line {
          @include centering-mode;
          flex-grow: 1;

          height: 4px;
          background-color: $bg-col-pattensBlue;
        }
      }
    }
  }

  .statictic {
    p {
      text-align: center;
      line-height: 40px;

      span {
        vertical-align: bottom;
        font-family: $OpenSansBold;
        font-size: 36px;
        color: $pacificBlue;
        margin: 0 20px;
      }
    }
  }
}

.author-course {
  &__title {
    text-align: center;
    margin-bottom: 93px;

    h2 {
      display: flex;
      flex-direction: column;
      line-height: 82px;

      span {
        font-size: 48px;
        color: $pacificBlue;
      }
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0 100px 76px;
  }

  &__img-wrap {
    min-width: 340px;
    margin: 2rem 30px 2rem 0;
  }

  .list {
    width: 56%;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 56px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      margin: 0;
      padding-left: 46px;
    }

    img {
      width: 45px;
      height: 100%;
      flex-shrink: 0;
    }
  }

  &__link-wrap {
    display: flex;
    justify-content: center;

    button {
      display: flex;
      align-items: center;

      &:hover {
        .btn-arrow {
          border-color: transparent;
          background-color: $bg-col-pattensBlue;
        }
      }

      span {
        margin-left: 38px;
      }
    }
  }
}

.reviews-section {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .comment-wrap {
    width: 100%;
  }

  &__title {
    text-align: center;
    margin-bottom: 100px;
  }

  &__result {
    display: flex;
    justify-content: center;

    p {
      display: flex;
      align-items: center;

      &:first-child {
        margin-right: 142px;
      }
    }

    strong {
      font-family: $OpenSansBold;
      font-size: 36px;
      color: $pacificBlue;

      margin: 0 20px;
    }
  }

  .reviews {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 72px;

    .comment-wrap {
      .comment {
        margin: 0 auto 40px;
      }
    }

    &__item {
      margin-bottom: 44px;
    }
  }

  .img-wrap-adapt {
    display: none;
  }

  .comment {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 32.75%;
    height: 241px;

    border-radius: 42px;
    background-color: $bg-col-aliceBlueStrong;
    margin-bottom: 40px;

    &:nth-child(2) {
      margin-right: 370px;
    }

    &:nth-child(4) {
      margin-right: 70px;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 45px;
      height: 33px;
      background-image: url('../images/other/parentheses.png');
      background-repeat: no-repeat;
    }

    &:before {
      top: 37px;
      left: 47px;
    }

    &:after {
      top: unset;
      left: unset;

      right: 47px;
      bottom: 64px;
    }

    p {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      padding: 0 100px;
    }
  }
}

.registration {
  &__container {
    max-width: 1332px;

  }

  &__title {
    text-align: center;
    margin-bottom: 108px;
  }

  &__text {
    margin-bottom: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 1440px) {
  .reviews-section {
    .reviews {
      &__middle {
        margin-bottom: 44px;
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .reviews-section {
    .reviews {
      .img-wrap {
        top: 48%;
        width: 250px;
      }
    }

    .comment {
      &:nth-child(2) {
        margin-right: 250px;
      }
    }
  }
}

@media screen and (max-width: 1365px) {
  .after-course {
    .statictic {
      p {
        span {
          margin: 0 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .intro {
    margin-bottom: 360px;

    &__wrap {
      position: relative;
      flex-direction: column;
      margin-bottom: 20px;
    }

    &__title {
      text-align: center;
      margin: 0 0 16px 0;
    }

    &__btn-wrap {
      display: flex;
      justify-content: center;
    }

    .left-block {
      &__text {
        text-align: center;
      }
    }

    .right-block {
      max-width: 100%;
      z-index: 1;
      position: absolute;
      bottom: -100px;
    }

    .chat {
      &__img-wrap {
        left: 50%;
        bottom: unset;
        transform: translateX(-50%);
      }

      &__message {
        display: none;
      }
    }
  }

  .author-course {
    &__title {
      margin-bottom: 10px;

      h2 {
        span {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }

    &__wrap {
      flex-direction: column;

      margin: 0 0 20px;
    }

    &__img-wrap {
      min-width: unset;
    }

    .list {
      min-width: 654px;
    }
  }
}

@media screen and (max-width: 960px) {
  .section-padding {
    padding: 55px 0;
  }

  .intro {
    margin-bottom: 37%;
  }

  .order-block {
    padding: 60px 0 80px;
    text-align: center;

    &__container {
      flex-direction: column;
    }

    &__img-wrap {
      top: unset;
      left: unset;
      bottom: -115px;
      right: 0;

      img {
        width: 180px;
      }
    }

    &__cnt {
      margin: 0 0 40px;
    }

    &__title {
      margin-bottom: 20px;
    }
  }

  .course {
    &__title {
      margin-bottom: 35px;
    }

    &__block {
      margin-bottom: 30px;
    }

    .block {
      flex-direction: column;
      align-items: center;

      &__img-wrap {
        margin: 0 0 15px;
      }

      &__title {
        text-align: center;
        margin-bottom: 27px;
      }

      &__list-wrap {
        margin: 0;
      }
    }
  }

  .study {
    overflow: hidden;

    &__title {
      margin-bottom: 35px;
    }

    .about-study {
      position: relative;
      flex-direction: column;
      margin-bottom: 0;

      &__desc-wrap {
        max-width: 100%;

        p {
          width: 75%;
          margin: 0 0 25px;
          padding-left: 75px;
        }
      }

      &__img-wrap {
        transform: rotate(353deg) translateY(-50%);
        position: absolute;
        left: unset;
        right: -300px;
        top: 50%;
      }
    }
  }

  .after-course {
    &__title {
      margin-bottom: 50px;
    }

    .steps-wrap {
      margin-bottom: 60px;
    }
  }

  .reviews-section {
    overflow: hidden;

    &__title {
      margin-bottom: 35px;
    }

    &__result {
      flex-direction: column;
      align-items: center;
      padding: 0 16px;

      p {
        text-align: center;

        &:first-child {
          margin-right: 0;
        }
      }
    }

    .reviews {
      display: flex;
      overflow: auto;
      //margin: 0 0 8px 16px;

      &__item {
        margin-bottom: 0;
      }

      .comment {
        width: 47.95%;

        &:nth-child(2) {
          margin-right: 30px;
        }

        &:nth-child(4) {
          margin-right: 30px;
        }

        //width: 300px;
        //margin-right: 30px;

        &:before {
          left: 25px;
        }

        &:after {
          right: 25px;
        }

        p {
          padding: 0 75px;
        }
      }

      &__bottom {
        .comment {
          &:first-child {
            margin-right: 30px;
          }
        }
      }

      .img-wrap {
        display: none;
      }
    }

    .img-wrap-adapt {
      display: block;
      margin: 0 auto;
      width: 300px;
    }
  }

  .registration {
    &__title {
      margin-bottom: 35px;
    }

    &__text {
      margin-bottom: 20px;

    }
  }
}

@media screen and (max-width: 768px) {
  .author-course {
    .list {
      width: 100%;
      min-width: unset;
    }
  }
}

@media screen and (max-width: 767px) {
  .reviews-section {
    &__container {
      padding: 0;
    }

    .reviews {
      flex-wrap: nowrap;
      justify-content: unset;
      margin: 0 0 8px 16px;

      .comment-wrap {
        width: unset;

        .comment {
          margin: 0 30px 0 0;
        }
      }

      .comment {
        width: 300px;
        flex-shrink: 0;
        margin-right: 30px;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .after-course {
    .steps-wrap {
      .steps-t {
        flex-direction: column;

        p {
          line-height: 24px;
          width: 100%;
          margin-bottom: 70px;
          padding: 0 50px;

          br {
            display: none;
          }

          &:first-child {
            margin-top: 8px;
          }

          &:nth-child(2) {
            margin-top: 13px;
          }

          &:last-child {
            width: 205px;
            margin: 0 auto;
            padding: 0;
          }
        }
      }

      .steps-b {
        position: absolute;
        top: 0;
        left: 0;

        flex-direction: column;

        width: 45px;
        height: 100%;

        padding: 0;

        .step {
          &__line {
            width: 4px;
            height: 100%;
          }
        }
      }
    }

    .statictic {
      p {
        font-size: 13px;
        line-height: 24px;

        span {
          font-size: 20px;
        }
      }
    }
  }

  .author-course {
    .list {
      p {
        padding: 0 20px 0 18px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .intro {
    margin-bottom: 175px;
  }

  .study {
    .about-study {
      line-height: 24px;

      &__desc-wrap {
        p {
          width: 85%;
          padding-left: 60px;

          &:before {
            width: 38px;
            height: 29px;

            background-size: 38px 29px;
          }
        }
      }

      &__img-wrap {
        right: -415px;

        img {
          width: 58%;
        }
      }
    }
  }

  .course {
    .block {
      &__img-wrap {
        max-width: 165px;
      }
    }
  }

  .author-course {
    &__wrap {
      margin-bottom: 38px;
    }

    .list {
      li {
        margin-bottom: 25px;
      }
    }
  }
}