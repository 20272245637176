.header-authorized {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: var(--background-primary);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    height: 4rem;

    @media (min-width: 768px) {
      padding: 0.75rem 2rem;
    }
  }
}

.user-nav {
  display: flex;
  align-items: center;
  gap: 1.25rem;

  .left-wrap {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .notification {
    position: relative;
    padding: 0.5rem;
    border-radius: 50%;
    background: transparent;
    transition: background 0.2s;

    &:hover {
      background: var(--background-secondary);
    }

    .bellIcon {
      width: 1.5rem;
      height: 1.5rem;
    }

    .indicator {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      width: 0.5rem;
      height: 0.5rem;
      background: var(--color-red);
      border-radius: 50%;
    }

    &.flashing .indicator {
      animation: pulse 2s infinite;
    }
  }
}

.user {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.25rem;
  border-radius: 2rem;
  transition: background 0.2s;

  &:hover {
    background: var(--background-secondary);
  }

  &__name {
    font-size: 0.9rem;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &__img-wrap {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__btn-arrow {
    padding: 0.25rem;
    transition: transform 0.2s;

    &:hover {
      transform: translateY(2px);
    }
  }
}

.burger-menu {
  position: fixed;
  top: 4rem;
  right: -100%;
  width: 100%;
  max-width: 300px;
  height: calc(100vh - 4rem);
  background: var(--background-primary);
  transition: right 0.3s ease;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  overflow-y: auto;

  &.is-open {
    right: 0;
  }

  li {
    margin-bottom: 0.75rem;
  }

  &__link {
    display: block;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    transition: background 0.2s;

    &:hover {
      background: var(--background-secondary);
    }
  }

  .user {
    padding: 1rem;
    background: var(--background-secondary);
    border-radius: 0.75rem;
    margin-bottom: 1rem;
  }
}

.list-settings {
  position: absolute;
  top: 100%;
  right: 2rem;
  width: 200px;
  background: var(--background-primary);
  border-radius: 0.75rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 0.5rem;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.2s;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  li {

    a,
    button {
      display: block;
      width: 100%;
      text-align: left;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;
      transition: background 0.2s;

      &:hover {
        background: var(--background-secondary);
      }
    }
  }

  .button--red {
    color: var(--color-red);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}