@import 'variables';
@import 'mixin';

.main-header {
  position: fixed;
  z-index: 99999;
  top: 0;
  width: 100%;

  background-color: $bg-col-aliceBlue;

  [data-theme=dark] & {
    background-color: #051826;
  }

  ul {
    list-style: none;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    max-width: 1710px;

    padding: 20px 16px;
  }

  .nav-wrap {
    display: flex;
    max-width: 1010px;
    width: 100%;

    .menu {
      display: flex;
      justify-content: space-between;
      width: 100%;

      a {
        display: flex;
        align-items: center;

        color: $darkCerulean;
        border-radius: 68px;

        padding: 0 50px;
        height: 50px;


        &:hover {
          background-color: $bg-col-pattensBlue;
        }

        [data-theme=dark] & {
          color: white;

          &:hover {
            background-color: #0a2d46;
          }
        }
      }
    }

    &__btns-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;

      max-width: 425px;
      width: 100%;

      .btn {
        max-width: 200px;
        width: 100%;

        display: flex;
        align-items: center;

        color: $darkCerulean;
        border-radius: 68px;

        padding: 0 50px;
        height: 50px;

        &:hover {
          background-color: $bg-col-pattensBlue;
        }

        [data-theme=dark] & {
          color: white !important;

          &:hover {
            background-color: #0a2d45;
          }
        }
      }
    }
  }

  .burger-menu {
    transition: 0.4s;
    display: block;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 0;
    width: 0;
    backdrop-filter: blur(1.6666666667rem);

    &.is-open {
      width: 100%;
      height: 100vh;

      .burger-menu {
        &__container {
          max-width: 590px;
          width: 100%;
        }
      }
    }

    &__container {
      overflow: hidden;
      transition: 0.5s;
      max-width: 0;
      padding-top: 120px;
      height: 100vh;
      background-color: $bg-col-aliceBlue;

      [data-theme=dark] & {
        background-color: #000811;
      }
    }

    button {
      flex-shrink: 0;
      font-family: $OpenSansBold;
      font-size: 20px;
      color: $darkCerulean;
      text-align: center;

      [data-theme=dark] & {
        color: #3383a5;
      }

      &:before {
        transition: 0.6s;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;

        width: 0;
      }

      &:hover {
        color: $pacificBlue;

        &:before {
          width: 100%;
          height: 2px;

          background-color: $bg-col-pacificBlue;
        }
      }
    }

    &__btns-wrap {
      text-align: center;

      .btn {
        max-width: 207px;

        &:before {
          display: none;
        }
      }

      .btn {
        margin: 0 auto;

        &:first-child {
          display: block;
          width: max-content;
          margin-bottom: 30px;
        }
      }
    }

    .burger-list {
      margin-bottom: 90px;
      padding-inline-start: 0;

      li {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .nav-list {
    display: flex;
    align-items: center;
    margin: 0 -5px;

    li {
      margin: 0 5px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .main-header {
    .nav-wrap {
      max-width: 810px;

      &__btns-wrap {
        max-width: 320px;
      }

      .menu {
        a {
          padding: 0 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .main-header {
    .container {
      padding: 5px 16px;
    }

    .nav-wrap {
      .nav-list {
        display: none;
      }
    }

    .burger-menu {
      backdrop-filter: unset;
      padding: unset;

      &.is-open {
        .burger-menu {
          &__container {
            max-width: 100%;
          }
        }
      }
    }
  }

  .main-header .nav-wrap__btns-wrap .btn {
    padding: 0;
  }
}