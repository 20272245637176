@import 'variables';
@import 'mixin';

.section-notification {
  &__container {
    padding: 0 6.7%;
  }

  .section-notification__title {
    margin-bottom: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;

    h2 {
      font-size: 24px;
      line-height: 33px;
      text-align: center;
    }
  }

  ul {
    .notification-item {
      cursor: pointer;
      font-size: 14px;
      position: relative;
      margin-bottom: 10px;
      padding: 10px 20px 10px 40px;
      width: 100%;
      min-height: 120px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $bg-col-pacificBlueOpacity;
      border-radius: 10px;

      &__text {
        margin-right: 20px;
      }

      &:before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        left: 16px;
        border-radius: 50%;
        background-color: $bg-col-pacificBlueOpacity;
      }
      &:hover {
        background-color: rgba(0, 79, 113, 0.15);
      }
    }

    .unread {
      background-color: $bg-col-pacificBlueOpacity;
      &.notification-item:before {
        background-color: $bg-col-cinnabar-red;
      }
    }
  }

  .load-more {
    margin: 0 auto;
    margin-top: 50px;
  }
}

.notification {
  &__container {
    padding: 0 11.3%;
  }

  &_empty {
    width: 100%;
    height: 86px;
    @include centering-mode;
  }

  .notification-list {
    position: unset;
    display: block;
    max-width: 100%;
    box-shadow: none;
    &:before {
      display: none;
    }

    li {
      &:last-child {
        border-bottom: 1px solid rgba(0, 158, 226, 0.15);
      }
    }

    .link-message {
      padding: 33px 0;
      &:hover {
        color: unset;
      }

      &__img-wrap {
        flex-shrink: 0;
      }

      &__left-side {
        max-width: 1000px;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .section-notification {
    .action-btn__wrap {
      max-width: 120px;
    }
  }

  .notification {
    .notification-list {
      width: inherit;
    }
  }
}

@media screen and (max-width: 600px) {
  .section-notification {
    ul {
      .notification-item {
        padding: 20px 20px 20px 40px;
        display: block;
        &__text {
          margin-right: 0;
        }

        .action-btn__wrap {
          margin: 0 auto;
          margin-top: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .notification {
    &__container {
      padding: 0 !important;
    }
    .notification-list {
      margin-top: -24px;
      li {
        height: 122px;
        //&:first-child {
        //  height: 102px;
        //  .link-message {
        //    padding: 0 16px 20px;
        //  }
        //}
      }
      .link-message {
        padding: 20px 16px;
      }
    }
  }
}
