@import 'variables';
@import 'mixin';

.affiliate-program {
  .container {
    max-width: 1520px;
    padding: 0 11.4%;
  }

  .title {
    margin-bottom: 20px;
  }

  &__desc {
    p {
      margin-bottom: 20px;
    }
  }

  &__inf {
    display: flex;
    align-items: center;
    justify-content: space-between;

    max-width: 350px;
    width: 100%;
    p {
      color: $comment;
    }

    strong {
      font-size: 20px;
      margin-left: 20px;
    }
  }
}

@media screen and (max-width: 480px) {
  .affiliate-program {
    &__inf {
      flex-direction: column;
      margin-bottom: 20px;
      p {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .btn-wrap {
      .btn {
        margin: 0 auto;
      }
    }
  }
}
