@import '../../assets/styles/variables.scss';

.trustpilot-widget {
  position: fixed;
  top: 90%;
  right: 0;
  z-index: 1000;
  /* Ensures the widget stays above other content */

  background-color: $bg-col-aliceBlue;
  border-radius: 12px;
  padding: 10px;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #0094853d;

  [data-theme=dark] & {
    background-color: #06aad9d4;
    color: #f6fcff;
  }

  &__header {
    font-weight: bold;
  }

  &__body {
    display: flex;
    align-items: center;
  }

  &__star {
    height: 24px;
    width: 24px;
  }

  &__star--icon {
    fill: $cloudyGreen;

    [data-theme=dark] & {
      fill: #f6fcff;
    }
  }
}

.trustpilot-widget-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
  border: 2px dotted #0094853d;
  border-radius: 20px;

  [data-theme=dark] & {
    background: #103957;
    color: #a6cfe0;

    &:hover {
      background: #245a81;
    }
  }
}