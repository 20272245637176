@import 'variables';
@import 'mixin';

.header-not-authorized {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  border-bottom: 1px solid rgba(0, 158, 226, 0.15);
  background-color: $bg-col-aliceBlue;

  &__container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    max-width: 1756px;

    padding: 13px 16px;
  }

  .btns-wrap {
    display: flex;
    justify-content: space-between;

    max-width: 400px;
    width: 100%;
  }
}
