@import '../../assets/styles/variables.scss';

.chat-toggle {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  bottom: 24px;
  right: 24px;
  border-radius: 50px;
  background: $bg-col-viking;
  box-shadow: 0 4px 15px rgba($darkCerulean, 0.15);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1000;
  color: $white;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba($darkCerulean, 0.2);
  }

  &:active {
    transform: translateY(0);
  }

  .chat-ai-icon {
    margin-right: 8px;
  }
}

.chat-widget {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 90px;
  right: 24px;
  width: 380px;
  height: 600px;
  border-radius: 16px;
  background: $bg-col-white;
  box-shadow: 0 8px 30px rgba($darkCerulean, 0.12);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  z-index: 999;

  [data-theme='dark'] & {
    background-color: $bg-col-blackPearlStrong;
    box-shadow: 0 8px 30px rgba($darkCerulean, 0.25);
  }

  .chat-header {
    padding: 16px 20px;
    background: $bg-col-viking;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: none;

    .header-text {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 18px;
      font-weight: 600;
    }

    .clear-history {
      padding: 8px;
      border-radius: 50%;
      transition: background 0.2s;
      color: $white;

      &:hover {
        background: rgba($white, 0.1);
      }
    }
  }

  .chat-content {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba($darkCerulean, 0.1);
      border-radius: 3px;

      [data-theme='dark'] & {
        background: rgba($white, 0.1);
      }
    }

    .messages {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .message {
        max-width: 85%;
        padding: 12px 16px;
        border-radius: 16px;
        font-size: 15px;
        line-height: 1.5;
        position: relative;

        &.user {
          align-self: flex-end;
          background: $bg-col-viking;
          color: $white;
          border-bottom-right-radius: 4px;
        }

        &.assistant {
          align-self: flex-start;
          background: $bg-col-border;
          border-bottom-left-radius: 4px;
          color: $darkCerulean;

          [data-theme='dark'] & {
            background: $bg-col-blackPearl;
            color: $white;
          }
        }
      }
    }
  }

  .input-box {
    padding: 16px;
    border-top: 1px solid $border-darkCerulean;
    background: $bg-col-white;

    [data-theme='dark'] & {
      background: $bg-col-blackPearlStrong;
      border-top: 1px solid $border-darkCerulean;
    }

    .input-area {
      position: relative;
      border: none;
      padding: 0;

      textarea {
        width: 100%;
        min-height: 48px;
        max-height: 150px;
        padding: 12px 16px;
        border-radius: 24px;
        border: 2px solid $border-darkCerulean;
        background: $bg-col-white;
        font-size: 15px;
        line-height: 1.5;
        transition: border-color 0.2s;
        color: $darkCerulean;

        &::placeholder {
          color: $glacier;
        }

        &:focus {
          border-color: $bg-col-viking;
          box-shadow: 0 0 0 4px rgba($bg-col-viking, 0.1);
        }

        [data-theme='dark'] & {
          background: $bg-col-blackPearlStrong;
          border-color: $border-darkCerulean;
          color: $white;

          &::placeholder {
            color: $glacier;
          }
        }
      }
    }

    button {
      width: 100%;
      margin: 12px 0 0;
      padding: 12px;
      border-radius: 24px;
      background: $bg-col-viking;
      color: $white;
      font-weight: 600;
      transition: all 0.2s;

      &:hover:not(:disabled) {
        transform: translateY(-1px);
        box-shadow: 0 4px 12px rgba($darkCerulean, 0.2);
      }

      &:disabled {
        background: $bg-col-border;
        color: $glacier;
        cursor: not-allowed;
        opacity: 0.7;

        [data-theme='dark'] & {
          background: $bg-col-blackPearl;
          color: $glacier;
        }
      }
    }
  }

  .footer-description {
    padding: 8px 0 0;
    font-size: 13px;
    color: $glacier;
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    [data-theme='dark'] & {
      color: $glacier;
    }

    p {
      margin: 0;

      b {
        color: $darkCerulean;

        [data-theme='dark'] & {
          color: $white;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .chat-widget {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    border-radius: 0;
    display: flex;
    transform: translateX(100%);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &.active {
      transform: translateX(0);
    }

    .chat-header {
      position: relative;
      padding-left: 52px; // Space for back button

      .mobile-back {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        padding: 8px;
        border-radius: 50%;
        transition: background 0.2s;
        color: $white;
        cursor: pointer;

        &:hover {
          background: rgba($white, 0.1);
        }

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .chat-toggle {
    bottom: 16px;
    right: 16px;
    padding: 16px;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    z-index: 99;

    span {
      display: none;
    }
  }
}