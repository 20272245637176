.rating {
  margin: 0 auto;
  padding: 40px 16px 0;
  max-width: 1068px;
  width: 90%;
}

.rating__main {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.rating__block {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.rating__flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .rating__flex {
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
  }
}

.rating__title {
  margin: 0;
  color: var(--color-element);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
}

@media screen and (max-width: 1360px) {
  .rating__title {
    font-size: 33px;
  }
}

@media screen and (max-width: 1280px) {
  .rating__title {
    font-size: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .rating__title {
    font-size: 27px;
  }
}

@media screen and (max-width: 768px) {
  .rating__title {
    font-size: 24px;
  }
}

.rating__total {
  margin: 0;
  color: var(--color-total-text);
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
}

@media screen and (max-width: 1360px) {
  .rating__total {
    font-size: 22px;
  }
}

@media screen and (max-width: 1280px) {
  .rating__total {
    font-size: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .rating__total {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .rating__total {
    font-size: 16px;
  }
}

/* table */
.rating__tables {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rating__table {
  width: 100%;
  box-sizing: border-box;
  padding: 8px 24px 8px 8px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.rating__table_color {
  border: 1px solid #d1eefb;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
}

[data-theme='dark'] {
  .rating__table_color {
    border: 1px solid rgba(0, 158, 226, 0.15);
    background: rgba(6, 41, 65, 0.4);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
  }
}

.rating__top_color {
  color: #0c182d;
}

@media screen and (max-width: 1156px) {
  .rating__table {
    align-items: flex-start;
    flex-direction: column;
    padding: 8px;
    gap: 16px;
  }
}

.rating__container {
  display: flex;
  align-items: center;
  gap: 24px;
}

@media screen and (max-width: 1280px) {
  .rating__container {
    gap: 16px;
  }
}

.rating__container_news {
  margin: 0 90px 0 auto;
  text-align: center;
}

@media screen and (max-width: 1280px) {
  .rating__container_news {
    margin: 0 70px 0 auto;
    text-align: center;
  }
}

@media screen and (max-width: 1156px) {
  .rating__container_news {
    margin: 0;
  }
}

.rating__avatar {
  object-fit: cover;
  object-position: center;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  border-radius: 64px;
  border: 1px solid rgba(0, 158, 226, 0.15);
}

.rating__content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

@media screen and (max-width: 1156px) {
  .rating__content {
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: 1156px) {
  .rating__content_news {
    flex-direction: column;
    align-items: start;
    width: 100%;
  }
}

.rating__text {
  margin: 0;
  color: var(--color-element);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  display: flex;
  gap: 10px;
}

.rating__text_name {
  width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rating__name {
  margin: 0;
  color: var(--color-table-light);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.rating__progresses {
  width: 369px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 24px;
  background: #eceff1;
}

[data-theme='dark'] {
  .rating__progresses {
    background: #081325;
  }
}

@media screen and (max-width: 1156px) {
  .rating__progresses {
    max-width: 375px;
    width: 100%;
  }
}

.rating__progress {
  --progress-one: #72cbf3;
  --progress-two: #14a8eb;
  --progress-three: #0c658d;
  --progress-four: #08435e;
  padding: 0 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: inherit;
  border-radius: inherit;
  -webkit-border-radius: inherit;
  -moz-border-radius: inherit;
  -ms-border-radius: inherit;
  -o-border-radius: inherit;
}

[data-theme='dark'] {
  .rating__progress {
    --progress-four: #72cbf3;
    --progress-three: #14a8eb;
    --progress-two: #0c658d;
    --progress-one: #08435e;
  }
}

.rating__percent {
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
}

.rating__place {
  display: flex;
  padding: 0px 8px;
  border-radius: 8px;
  align-items: center;
  gap: 4px;
}

.rating__top {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
}

.rating__top_color {
  color: white;
}

.rating__icon {
  width: 16px;
  object-fit: cover;
  object-position: center;
}

/* sites */
.rating__sites {
  padding: 16px 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

@media screen and (max-width: 768px) {
  .rating__sites {
    padding: 16px 0 80px;
  }
}

.rating__site {
  background: var(--color-transparent);
  border-radius: 40px;
  border: 2px solid var(--color-border-light);
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  cursor: pointer;
}

.rating__site:hover:not(:disabled) {
  opacity: 0.7;
}

.rating__site:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.rating__site_backward {
  padding: 13px 11px 11px 11px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.rating__site_forward {
  padding: 13px 11px 11px 11px;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
}
