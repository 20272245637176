@import 'variables';
@import 'mixin';

@keyframes firstStartRipple {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 20px 0 #95f0ca;
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
    box-shadow: 0 0 20px 0 #95f0ca;
  }
  50% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 #95f0ca;
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 20px 0 #95f0ca;
  }
}

.coupons {
  padding: 34px 0;
  &__desc-wrap {
    max-width: 825px;
    width: 100%;

    margin: 0 auto;

    text-align: center;
    .text-wrap {
      display: flex;
      align-items: center;
      justify-content: left;

      font-size: 14px;
      line-height: 20px;

      padding: 20px 40px;
      min-height: 75px;
      border-radius: 30px;
      background-image: linear-gradient(-89.79deg, #c0e8ff 0.17%, #dcf4ff 99.82%);
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      &:last-child {
        border-radius: 30px 30px 0 30px;
      }

      span {
        font-family: $OpenSansBold;
        font-size: 18px;
        color: $pacificBlue;
      }
    }
    p {
      margin: 0;
    }
  }

  &__img-wrap {
    max-width: 370px;
    width: 100%;
    margin: 10px auto;
  }

  &__btn-wrap {
    width: fit-content;
    animation: firstStartRipple 1.5s linear 0.5s;
    margin: 0 auto;
    border-radius: 68px;
  }
  &__btn {
    animation: ripple 2s linear 2s 5;
    &:hover {
      color: $darkCerulean;
    }
  }
}
