@import '../../assets/styles/variables.scss';

._wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.timeline {
    display: none;
    border-top: $pacificBlue 2px solid;
    border-bottom: $pacificBlue 2px solid;

    &.opened {
        display: unset;
    }

    margin-top: 10px;
    padding: 10px 0;

    .stage {
        display: flex;
        gap: 3px;
        align-items: center;
        justify-content: space-between;

        position: relative;
        padding: 5px 8px;
        font-size: 12px;
        border-radius: 20px;

        [data-theme=light] & {
            color: #237699;
        }


        .stage-price {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 22px;
            padding: 0 10px;
            gap: 5px;

            @media screen and (max-width: 768px) {
                display: none;
            }
        }

        .stage-description {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;

            &__discount {
                font-size: 12px;
                color: $shamrock;
            }

        }


        &.active {
            background-color: rgba(0, 158, 226, 0.1666666667);
            color: #007fb6;

            [data-theme=dark] & {
                color: #ffffff;
            }
        }



        &.trial {
            color: $shamrock;
        }

        .cycle-text {
            font-size: 12px;

            [data-theme=dark] & {
                color: rgba($white, 0.5);
            }
        }
    }
}

.billing-cycle-switch {
    max-width: 50%;
    display: flex;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid $pacificBlue;
    margin-bottom: 20px; // Adjust the margin as necessary

    @media screen and (max-width: 768px) {
        max-width: 80%;
    }

    button {
        flex: 1;
        padding: 10px 15px; // Padding is adjusted for a more balanced look
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
        transition: border 0.2s ease-in-out, color 0.2s ease-in-out;

        border-radius: 14px;

        &:focus {
            outline: none;
        }

        &.active {
            background-color: $pacificBlue;
            border: 1px solid $pacificBlue;
            color: #ffffff;
        }

        // Ensuring the ext does not wrap or break onto a new line
        white-space: nowrap;


    }
}

.option-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;

    }

    h3 {
        color: $pacificBlue;
        margin: 0;
        font-family: "OpenSansBold";
        font-size: 34px;

        @media screen and (max-width: 768px) {
            font-size: 24px;
        }
    }

    .stage__trial {
        padding: 5px 15px;
        border-radius: 20px;
        font-size: 14px;
        background: rgba($shamrock, 0.1);
        color: $shamrock;

        @media screen and (max-width: 768px) {
            padding: 5px 8px;
            font-size: 11px;
        }

        [data-theme=dark] & {
            background: rgba($shamrock, 0.2);
            color: rgba($shamrock, 0.9);
        }

    }
}

.option-calendar-button {
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
        width: 30px;
        height: 40px;
        stroke: $pacificBlue;
    }

    &__text {
        font-size: 14px;
        color: $pacificBlue;

        @media screen and (max-width: 768px) {
            font-size: 12px;
        }
    }
}

.option-price-block {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 15px;
    }

}


.tooltip-wrapper+[data-id=tooltip] {
    @media screen and (max-width: 768px) {
        display: none;
    }

    border-radius: 20px;
    max-width: 200px;
    line-height: 1.5;
}