@import 'variables';
@import 'mixin';

.header-webinar {
  padding: 42px 0;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;

  width: 100%;
  background-color: $bg-col-aliceBlue;

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__left {
    p {
      text-align: center;
      margin: 0;

      &:first-child {
        color: $darkCerulean;
        opacity: 0.5;
      }

      &:last-child {
        color: $pacificBlue;
      }
    }
  }

  &__center {
    display: flex;
    align-items: center;
    text-align: center;

    .wrap {
      margin-right: 18px;
    }

    p {
      margin: 0;

      &:last-child {
        color: $pacificBlue;
      }
    }
  }

  .btn {
    max-width: 214px;
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .header-webinar {
    padding: 25px 0;

    .btn {
      max-width: 170px;
      height: 55px;
    }
  }
}

@media screen and (max-width: 640px) {
  .header-webinar {
    padding: 5px 0;
    &__wrap {
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 -12px;
    }

    &__left,
    &__center,
    .btn {
      margin: 0 12px 0;
    }
    &__center {
      svg {
        display: none;
      }
    }

    .btn {
      display: none;
    }
  }
}
