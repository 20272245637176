@import 'variables';
@import 'mixin';

.ethers {
  &__container {
    padding: 0 6.7%;
  }

  .article {
    width: 50%;

    a {
      flex-direction: unset;
    }

    &__img-wrap {
      max-width: 295px;
      min-width: unset;
      height: 176px;
      padding-top: unset;
      margin: 0 48px 0 0;

      img {
        object-fit: cover;
      }

      &:before {
        display: none;
      }
    }

    &__desc {
      -webkit-line-clamp: 4;
      height: 90px;
    }
  }
}

.details-ether {
  &__container {
    padding: 0 5.32%;
  }

  &__title {
    margin-bottom: 46px;

    h2 {
      font-size: 24px;
      line-height: 33px;
    }
  }

  .ether-wrap {
    max-width: 1046px;
    width: 100%;
  }
  .ether {
    width: inherit;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;

    margin-bottom: 24px;
    &.blocked,
    &.unblocked {
      position: relative;
      &:before {
        position: absolute;
        z-index: 1;
        content: '';
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: linear-gradient(89.79deg, #c0e8ff 0.17%, #dcf4ff 99.82%);
        mix-blend-mode: hard-light;
      }
      .blocked-wrap,
      .unblocked-wrap {
        max-width: 247px;
        width: 100%;
        z-index: 9;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .btn {
          height: 45px;
        }
      }

      .unblocked-wrap {
        max-width: unset;
        text-align: center;
        padding: 0 20px;
        svg {
          margin-bottom: 20px;
        }
        &__date {
          font-family: $OpenSansBold;
          font-size: 36px;
          line-height: 49px;

          margin-bottom: 28px;
        }
      }

      .blocked-icon {
        position: unset;
        border: none;
        transform: unset;
        width: max-content;
        height: max-content;
        margin: 0 auto 70px;
      }

      .btn {
        width: 100%;
        font-size: 14px;

        margin: 0 auto;
      }
    }

    &.video {
      div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__link {
      position: absolute;
      top: 56px;
      right: 70px;

      color: $darkCerulean;
      z-index: 10;
      &:hover {
        color: $pacificBlue;
      }

      .indicator {
        position: absolute;
        top: 0;
        right: -12px;

        background-color: $bg-col-cinnabar-red;
      }
    }
  }

  .about-ether {
    font-size: 14px;
    display: flex;
    justify-content: space-between;

    &__text {
      width: 70%;
      margin-right: 30px;
    }

    .status {
      display: flex;
      justify-content: flex-end;
      overflow: hidden;
      -webkit-line-clamp: unset;
      -webkit-box-orient: unset;
      width: 220px;
    }
  }

  .poster {
    background-image: url('../images/other/poster.png');
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 1279px) {
  .ethers {
    .list-articles {
      margin: 0;
    }

    .article {
      width: 100%;
      padding: 0;
      margin: 0 0 25px;
    }
  }
}

@media screen and (max-width: 767px) {
  .details-ether {
    .ether {
      &__link {
        top: 20px;
        right: 20px;
      }

      &.unblocked {
        .unblocked-wrap {
          svg {
            width: 30px;
            height: auto;
            margin-bottom: 0;
          }
          &__date {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .ethers {
    .article {
      &__wrap {
        margin-bottom: 7px;
      }

      &__img-wrap {
        max-width: 168px;
        height: 100px;
        margin-right: 15px;
      }

      &__title {
        margin-bottom: 6px;

        h3 {
          font-size: 15px;
          line-height: 24px;
        }
      }

      &__desc {
        -webkit-line-clamp: 3;
        font-size: 12px;
        line-height: 19px;
        height: 60px;
      }

      .status {
        white-space: unset;
      }
    }
  }

  .details-ether {
    .blocked {
      &:before {
        position: absolute;
        z-index: 1;
        content: '';
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: linear-gradient(89.79deg, #c0e8ff 0.17%, #dcf4ff 99.82%);
        mix-blend-mode: hard-light;
      }
    }
    .ether {
      &.blocked {
        position: relative;
        .blocked-icon {
          margin: 0 auto 20px;
          svg {
            width: auto;
            height: 40px;
          }
        }

        .btn {
          width: 80%;
          height: 40px;
        }
      }
    }
  }
}
