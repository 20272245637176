@import 'variables';

.todo-block {
  position: relative;
}

.todo-block:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: auto auto;
  background-color: rgba(255, 255, 255, 0);
  background-image: repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(255, 1, 1, 0.2) 10px, rgba(255, 1, 1, 0.2) 20px);
  cursor: not-allowed;
}
