//begin fonts
$OpenSansRegular: 'OpenSansRegular';
$OpenSansBold: 'OpenSansBold';
$FantasqueSansMonoRegular: 'FantasqueSansMonoRegular';
$FantasqueSansMonoItalic: 'FantasqueSansMonoItalic';

//end fonts

//begin colors
$lavender: #526cfe;
$pacificBlue: #009ee2;
$aliceBlue: #f6fcff;
$darkCerulean: #004f71;
$darkCeruleanOpacity: rgba(0, 79, 113, 0.5);
$shamrock: #34e590;
$cloudyGreen: #009485;
$cinnabar-red: #e63434;
$glacier: #7ba5b8;
$white: #ffff;
$lightCyan: #ffff;
$white-opacity: rgba(255, 255, 255, 0.5);
$red: #ff0000ff;
$darkCerulean: #004f71;
$keyword: #c792ea;
$string: #ecc48d;
$title: #dcdcaa;
$params: #7fdbca;
$function: #82aaff;
$comment: #637777;
$built-in: #addb67;
$number: #f78c6c;
$literal: #ff5874;
$subst: #d3423e;
$bullet: #d9f5dd;
$code-light-theme: #075472;
$code-dark-theme: #8adeff;
$blackPearlStrong: #04131e;
//end colors

//begin border-colors
$border-red: #ff0000ff;
$border-pacificBlue: #009ee2;
$border-shamrock: #34e590;
$border-aliceBlueStrong: #ddf3ff;
$border-darkCerulean: #004f71;
$border-col-cinnabar: #e53434;
//end border-colors

//begin bg-colors
$bg-col-pacificBlue: #009ee2;
$bg-col-viking: rgba(75, 163, 179, 0.9);
$bg-col-blackPearl: #051826;
$bg-col-blackPearlStrong: #00192c;
$bg-col-border: #003052;
$bg-col-pacificBlueOpacity: rgba(0, 158, 226, 0.15);
$bg-col-aliceBlue: #f6fcff;
$bg-col-aliceBlueStrong: #ddf3ff;
$bg-col-darkCerulean: #004f71;
$bg-col-pattensBlueStrong: rgba(0, 79, 113, 0.15);
$bg-col-pattensBlue: #d9f1fb;
$bg-col-white: #ffffff;
$bg-col-shamrock: #34e590;
$bg-col-cinnabar-red: #e63434;
$bg-col-cinnabar-red-opacity: rgba(242, 32, 32, 0.45);
$bg-col-code-light-theme: #d0dde5fa;
$bg-col-code-dark-theme: rgba(29, 56, 72, 0.98);
$bg-col-gossamer: #3eaa96;
//end bg-colors

// CSS Custom Properties
:root {
    // Light theme variables
    --background-primary: #{$bg-col-aliceBlue};
    --background-secondary: #{rgba($bg-col-pattensBlueStrong, 0.03)};
    --primary-color: #{$pacificBlue};
    --text-primary: #{$darkCerulean};
    --text-muted: #{$darkCeruleanOpacity};
    --color-red: #{$border-col-cinnabar};
    --border-color: #{$bg-col-pacificBlueOpacity};
    --scrollbar-track: #{$bg-col-pattensBlueStrong};
    --scrollbar-thumb: #{$bg-col-pacificBlue};
}

[data-theme='dark'] {
    --background-primary: #{$bg-col-blackPearl};
    --background-secondary: #{rgba($bg-col-blackPearlStrong, 0.03)};
    --primary-color: #{$pacificBlue};
    --text-primary: #{$white};
    --text-muted: #{$white-opacity};
    --color-red: #{$cinnabar-red};
    --border-color: #{$bg-col-border};
    --scrollbar-track: #{rgba($bg-col-blackPearlStrong, 0.5)};
    --scrollbar-thumb: #{rgba($pacificBlue, 0.8)};
}