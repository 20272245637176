.onboarding-video-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: var(--primary-color);

  &__title {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__button {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .btn {
      font-size: 18px;
      font-weight: bold;
      color: var(--background-section);
      max-width: unset;
      transition: all 0.3s ease-in-out;
    }
  }

  .video-content {
    background: var(--background-section);
    border-radius: 15px;
    box-shadow: 0 2px 5px var(--shadow-color);
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding: 20px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; // Distributes space between items vertically
  }
}

.return-link {
  margin-top: 40px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  pre {
    cursor: pointer;
  }
}
