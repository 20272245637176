@import 'variables';
@import 'mixin';

.sidebar {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 180px;
  width: 100%;
  height: 100vh;

  border-right: 1px solid rgba(0, 158, 226, 0.15);
  //background-color: $bg-col-aliceBlue;
}

.sidebar-nav {
  width: 100%;

  &__item-icon {
    width: 35px;
  }

  .indicator {
    position: absolute;
    top: 0;
    right: -12px;
    background-color: $bg-col-cinnabar-red;
  }

  &__list {
    li {
      margin-bottom: 0;
      text-align: left;
      padding: 20px 20px;
      padding-left: 5px;

      @media screen and (max-width: 1023px) {
        padding: 0;
      }

      &.active {
        border-right: 1px solid #46a1e3;
        background: #0b2a41;
      }

      &:last-child {
        margin-bottom: 0;
      }

      a {

        svg {
          display: block;
        }

        display: flex;
        // flex-direction: column;
        padding-left: 24px;
        align-items: center;
        color: $darkCerulean;

        &:hover {
          color: $pacificBlue;
        }

        &.active {
          svg {
            path {
              fill: $pacificBlue;
            }
          }

          color: $pacificBlue;
        }
      }
    }
  }
}

//@media screen and (max-width: 1365px) {
//  .sidebar {
//    max-width: 200px;
//  }
//}

@media screen and (max-width: 1023px) {
  .sidebar {
    z-index: 999;
    top: unset;
    bottom: 0;

    max-width: 100%;

    padding-top: 0;
    backdrop-filter: blur(0.9877778rem);
    height: auto;
    background: #ebfaffd4;
  }

  .sidebar-nav {
    overflow-x: hidden;
    margin-top: 0;
    padding: 10px 0;
    align-items: center;
    &__item-icon {
      width: unset;
    }
    :first-child {
      padding-left: 0;
    }

    &__list {
      display: flex;
      justify-content: space-around;

      li {
        margin-bottom: 0;

        a {
          display: flex;
          flex-direction: column;
          align-items: center;

          svg {
            display: block;
          }

          font-size: 13px;
        }
      }
    }
  }
}