@import 'variables';
@import 'mixin';

.not-authorization {
  .dashboard {
    margin-top: 0;
  }

  .dashboard-main {
    height: calc(100vh - 130px);
    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      > p {
        text-align: center;
      }
    }
  }
}
