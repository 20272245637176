:root {
  --accent-color: #009ee285;
  --accent-color-hover: #97cee672;
  --accent-color-text: #fff;

  --primary-color: #333; /* Darker text for readability */
  --primary-color-light: lighten(#333, 10%);
  --background-color: #f6fcff;
  --background-section: rgba(74, 173, 222, 0.2);
  --shadow-color: rgba(0, 0, 0, 0.1); /* Subtle shadows for depth */
  --background-color-light: #ffffff97; /* Pure white for cards and elements */
}

[data-theme='dark'] {
  --primary-color: #eee;
  --primary-color-light: lighten(#eee, 10%);
  --background-color: #04131e;
  --shadow-color: rgba(#002b3e85, 0.1);
  --background-color-light: #004b64;
  --accent-color: #009ee2c6;
  --accent-color-hover: #009ee285;
}

.test-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: var(--primary-color);
  .progress-bar-container {
    width: 100%;
    background-color: var(
      --accent-color-hover
    ); // A light background color for the container
    border-radius: 5px;
    margin-bottom: 1rem;

    .progress-bar {
      height: 10px;
      background-color: var(
        --scrollbark-thumb
      ); // Use a primary color for the progress bar itself
      border-radius: 5px;
      transition: width 0.3s ease-in-out;
    }
  }
  .test-content {
    background: var(--background-section);
    border-radius: 15px;
    box-shadow: 0 2px 5px var(--shadow-color);
    margin: auto;
    padding: 20px;
    width: 100%;
    max-width: 600px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; // Distributes space between items vertically

    &__header {
      font-style: italic;
      margin-bottom: 20px;
    }

    .page-number {
      text-align: center;
      color: var(--primary-color);
      margin-bottom: 1rem;
    }
    .question {
      display: none; // Hide all questions initially
      &.active {
        display: block; // Show the active question
      }

      .question-text {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .option {
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        margin-bottom: 15px;
        padding: 10px;
        background: var(--background-color-light);
        border-radius: 15px;
        border: none;
        box-shadow: 0 2px 5px var(--shadow-color);
        &:hover {
          background-color: var(--accent-color-hover);
          color: var(--primary-color);
        }

        input[type='radio'] {
          appearance: none; // Remove default checkbox style
          background-color: var(--background-color);
          margin: 0 15px 0 0;
          font: inherit;
          color: currentColor;
          width: 2em;
          height: 2em;
          border-radius: 40%;
          transform: translateY(-0.075em);
          display: grid;
          place-content: center;

          &::before {
            content: '';
            width: 1em;
            height: 1em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em 0 var(--accent-color);
            border-radius: 50%;
          }

          &:checked::before {
            transform: scale(1);
          }
        }

        span {
          font-size: 16px;
        }
      }
    }

    .navigation-buttons {
      display: flex;
      margin-top: auto; // This pushes the navigation buttons to the bottom

      justify-content: space-between;
      margin-top: 20px;

      button {
        padding: 10px 20px;
        border: none;
        background-color: var(--background-color-light);
        color: var(--primary-color);
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          background-color: var(--accent-color-hover);
        }

        &.prev {
          margin-right: auto;
        }

        &.next {
          margin-left: auto;
        }
      }
    }
  }
}

.submit-container {
  display: flex;
  justify-content: center; // This will center the button horizontally
  padding-top: 20px; // Add space between the navigation buttons and the submit button
  margin-top: auto; // This pushes the navigation buttons to the bottom
  width: 100%;
}

.submit {
  padding: 10px 50px;
  border: none;
  background-color: var(--accent-color);
  color: var(--accent-color-text);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--accent-color-hover);
  }
}

@media (max-width: 768px) {
  .test-container {
    .test-content {
      width: 90%;
    }
  }
}
