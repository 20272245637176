@import 'variables';

.error404 {
  .container {
    min-height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  padding: 20px 0;
  .title {
    h1 {
      font-size: 120px;
      line-height: 98px;
      margin-bottom: 20px;
    }
  }

  &__cnt-wrap {
    display: flex;
    align-items: center;
  }
  &__img-wrap {
    width: 50%;
    img {
      max-width: 800px;
      width: 100%;
    }
  }

  &__right-side {
    width: 50%;
  }

  &__desc {
    p {
      margin-bottom: 0;
      &:last-child {
        margin-bottom: 20px;
      }
    }
  }

  &__btn {
    flex-shrink: 0;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .error404 {
    .container {
      min-height: unset;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__cnt-wrap {
      flex-direction: column;
    }

    &__img-wrap {
      max-width: 300px;
      width: 100%;
      min-width: unset;
    }

    &__right-side {
      text-align: center;
      width: 100%;
    }
  }
}
