@import '../../assets/styles/variables.scss';

:root {
  --modal-bg: #f6fcff;
  --modal-text-color: #04131e;
  --modal-bg-opacity: #cbf0ff7f;
  --modal-button-bg: #ffffff;
  --modal-button-hover: #f5f9fc;
  --modal-button-border: rgba(0, 133, 226, 0.15);
  --modal-button-shadow: rgba(0, 133, 226, 0.1);
  --modal-dark-bg: #0d1117;

  --scrollbark-thumb: rgba(75, 163, 179, 0.9);

  [data-theme=dark] {
    --modal-bg: var(--modal-dark-bg);
    --modal-text-color: #f6fcffff;
    --modal-bg-opacity: #009ee226;
    --modal-button-bg: rgba(255, 255, 255, 0.05);
    --modal-button-hover: rgba(255, 255, 255, 0.1);
    --modal-button-border: rgba(255, 255, 255, 0.1);
    --modal-button-shadow: rgba(0, 0, 0, 0.2);
    --scrollbark-thumb: #7ba5b8;
  }
}

.modal {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 1000;
  box-sizing: border-box;

  &.log-in {
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(8px);
  }

  &__wrap {
    &.log-in {
      width: min(420px, 100%);
      background: var(--modal-bg);
      border-radius: 24px;
      box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
      padding: 32px 24px;

      @media (max-width: 480px) {
        width: 100%;
        padding: 24px 16px;
        border-radius: 16px;
      }

      .modal__close {
        position: absolute;
        top: 16px;
        right: 16px;
        background: none;
        border: none;
        color: var(--modal-text-color);
        font-size: 20px;
        cursor: pointer;
        opacity: 0.6;
        transition: all 0.2s ease;
        padding: 8px;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          opacity: 1;
          background: var(--modal-button-hover);
        }
      }

      .wrapper__socials {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 100%;
        margin: 0 auto;

        .banner {
          background: var(--modal-bg);
          color: var(--modal-text-color);
          padding: 0;
          margin-bottom: 32px;
          box-shadow: none;
          text-align: center;
          width: 100%;
          max-width: 100%;
          border-radius: 0;

          h2 {
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 24px;
            background: linear-gradient(135deg, #0085e2, #00c2ff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding: 0;
            width: 100%;

            @media (max-width: 480px) {
              font-size: 24px;
            }
          }

          p {
            font-size: 15px;
            line-height: 1.6;
            color: var(--modal-text-color);
            opacity: 0.8;
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            width: 100%;
            padding: 0 8px;
            box-sizing: border-box;

            &:last-of-type {
              margin-bottom: 0;
            }
          }

          .icon-banner {
            font-size: 64px;
            margin: 32px 0;

            @media (max-width: 480px) {
              font-size: 48px;
              margin: 24px 0;
            }
          }
        }

        .title {
          text-align: center;
          margin-bottom: 24px;
          width: 100%;

          h2 {
            font-size: 18px;
            font-weight: 500;
            opacity: 0.9;
            margin: 0;
            padding: 0 16px;
          }
        }
      }
    }
  }

  &__cnt {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding: 0;
    max-width: 100%;
    margin: 0 auto;

    .icon-link {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 16px;
      background: var(--modal-button-bg);
      border: 1px solid var(--modal-button-border);
      border-radius: 12px;
      color: var(--modal-text-color);
      font-size: 16px;
      font-weight: 500;
      text-decoration: none;
      transition: all 0.2s ease;
      width: 100%;
      height: 48px;
      position: relative;
      box-sizing: border-box;
      margin: 0;

      &:hover {
        background: var(--modal-button-hover);
        border-color: var(--modal-button-border);
        transform: translateY(-1px);
        box-shadow: 0 4px 12px var(--modal-button-shadow);
      }

      .icon {
        position: absolute;
        left: 16px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 20px;
          height: 20px;
        }

        &.google svg {
          fill: #4285f4;
        }

        &.github svg {
          fill: #24292e;

          [data-theme='dark'] & {
            fill: #ffffff;
          }
        }
      }

      .text {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        padding: 0;
      }
    }
  }
}

.modal__wrap.search {
  background-color: var(--modal-bg);
  display: flex;
  flex-direction: column;
  width: 70%;
  max-height: 90vh;
  overflow-y: auto;
  margin-top: 5vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}

.search-form {
  top: 0;
  z-index: 10;

  input {
    background: var(--modal-bg-opacity);
    color: var(--modal-text-color);
    font-size: 16px;

    &::placeholder {
      color: var(--modal-text-color);
      filter: opacity(0.5);
    }
  }
}

.search-results {
  width: 100%;
  overflow-y: auto;
  padding-right: 20px;
}

.modal-lesson-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;
  padding: 20px;
  background: rgba(74, 173, 222, 0.05);
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 15px;
}

.modal-lesson-card__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-lesson-card__image {
  flex: 0 0 100px;
  height: 130px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}

.modal-lesson-card__title {
  color: var(--modal-text-color);
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.3rem;
  text-align: center;
}

.modal-lesson-card__description {
  color: var(--modal-text-color);
  font-size: 0.85rem;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-bottom: 1rem;
}

.registration-checkboxes {
  margin-top: 24px;
  text-align: center;
  padding: 0;
  width: 100%;
  max-width: 100%;

  label {
    font-size: 13px;
    line-height: 1.5;
    color: var(--modal-text-color);
    opacity: 0.7;
    display: block;
    padding: 0 16px;
    margin: 0;
    width: 100%;
    box-sizing: border-box;

    a {
      color: #0085e2;
      text-decoration: none;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: 768px),
screen and (max-width: 640px) {
  .modal__wrap.search {
    width: 85%;
    margin-top: 0;
  }

  .modal-lesson-card {
    flex-direction: column;
    align-items: stretch;
    padding: 15px;
  }

  .modal-lesson-card__title {
    text-align: start;
  }
}

@media screen and (min-width: 1500px) {
  .modal-lesson-card__image {
    flex: 0 0 150px;
    height: 200px;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: var(--modal-bg-opacity);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbark-thumb);
  border-radius: 10px;
}

.banner {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  border-radius: 18px;
}

.banner h2 {
  font-size: 28px;
  line-height: 34px;
  margin: 0;
  text-align: center;
}

.banner p {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  text-align: center;
}

.icon-banner {
  font-size: 3em;
  margin-top: 10px;
}