@import 'variables';
@import 'mixin';

@keyframes border-rotate {
  to {
    transform: rotate(365deg);
  }
}

.webinar-wrap {
  padding-top: 204px;

  .title {
    h1,
    h2 {
      font-size: 64px;
      line-height: 87px;
    }
  }
}

.webinar {
  .about-webinar {
    align-items: center;
    justify-content: space-between;
    display: grid;
    grid-template-areas:
      'cnt video-block'
      'webinar-start video-block';
    grid-template-columns: 660px 46.6%;
    grid-column-gap: 30px;

    .another-color {
      color: $pacificBlue;
    }

    &__cnt {
      grid-area: cnt;
    }

    &__title {
      margin-bottom: 48px;
    }

    &__video-block {
      grid-area: video-block;
    }

    .video-wrap {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      //&:before {
      //  content: "";
      //  position: absolute;
      //  left: 0;
      //  top: 0;
      //  width: 100%;
      //  height: 100%;
      //  background-image: linear-gradient(180deg, rgba(0,158,226,0) .17%, rgba(0,158,226,0.3) 99.82%);
      //  z-index: 2;
      //  border-radius: 10px;
      //}

      video,
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        border-radius: 10px;
      }
    }
  }

  .webinar-start {
    grid-area: webinar-start;

    > p {
      color: $darkCeruleanOpacity;
    }

    .timer {
      margin-bottom: 40px;
    }

    .btn {
      max-width: 192px;
      width: 100%;
    }
  }

  .timer {
    display: flex;
    justify-content: space-between;
    text-align: center;

    max-width: 600px;
    width: 100%;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      width: 129px;
      height: 129px;

      &:before {
        animation: border-rotate infinite;
        animation-timing-function: linear;
        content: '';
        position: absolute;
        top: 0;
        left: 0;

        border: 5px solid transparent;
        border-image: radial-gradient(6.68% 77.86% at 0 54.61%, rgba(0, 158, 226, 0.3) 0, #dcf4ff 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        background: radial-gradient(6.68% 77.86% at 0 54.61%, rgba(0, 158, 226, 0.3) 0, #dcf4ff 100%);
        border-image-slice: 1;
        border-radius: 50%;
        -webkit-mask-composite: destination-out;
        width: inherit;
        height: inherit;
      }

      &:first-child {
        &:before {
          animation-duration: 60s;
        }
      }

      &:nth-child(2) {
        &:before {
          animation-duration: 30s;
        }
      }

      &:nth-child(3) {
        &:before {
          animation-duration: 10s;
        }
      }

      &:last-child {
        &:before {
          animation-duration: 5s;
        }
      }
    }

    &__number {
      font-family: $OpenSansBold;
      font-size: 36px;
      line-height: 49px;
    }

    &__name {
      color: $darkCeruleanOpacity;
    }
  }
}

.speakers {
  overflow: hidden;
  &__title {
    margin-bottom: 62px;
  }

  .speakers {
    &__wrap {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -4.5%;
    }
  }

  .speaker {
    display: flex;
    align-items: center;

    width: 41.7%;

    margin: 0 4.15% 4.15%;

    &__img-wrap {
      position: relative;
      margin: 0 60px 0 18px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        border-radius: 50%;
        box-shadow: 0 0 0 8px rgba(0, 158, 226, 0.15), 0 0 0 20px rgba(0, 158, 226, 0.15);
      }

      img {
        border-radius: 50%;
        width: 205px;
        height: 205px;
      }
    }

    &__name {
      display: block;
      font-family: $OpenSansBold;
      font-size: 36px;
      line-height: 49px;
      margin-bottom: 20px;
    }

    &__desc {
      color: $darkCeruleanOpacity;
    }
  }
}

.webinar-program {
  &__title {
    margin-bottom: 94px;
  }

  &__list {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      border-bottom: 1px solid rgba(0, 158, 226, 0.15);

      margin-bottom: 20px;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }

      &__title-block {
        display: flex;
        align-items: center;
        margin-right: 30px;
        min-width: 370px;
      }

      &__number {
        width: 60px;

        font-family: $OpenSansBold;
        font-size: 96px;
        line-height: 130px;

        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        background-image: linear-gradient(180deg, #b7e6ff 12.08%, #ddf3ff 100%);
        margin-right: 60px;
      }

      &__title {
        max-width: 472px;
        width: 100%;

        h3 {
          color: $pacificBlue;
        }
      }

      &__text {
        max-width: 920px;
        width: 100%;

        color: $darkCeruleanOpacity;
      }
    }
  }
}

.part-webinar {
  padding-bottom: 71px;
  &__subscription {
    margin-left: 45px;
  }
  &__top-block {
    max-width: 752px;
    width: 100%;
    margin: 45px 0 40px 0;
    .btn {
      max-width: 362px;
      width: 100%;
      margin-bottom: 65px;
    }
  }
  &__title {
    max-width: 700px;
    width: 100%;
    margin-bottom: 26px;
  }

  .log-in {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 324px;
    width: 100%;
    padding: 25px 16px;

    border-radius: 24px;
    background-color: $bg-col-pacificBlueOpacity;

    margin-bottom: 168px;

    > p {
      margin-bottom: 10px;
    }

    &__links {
      display: flex;
      flex-wrap: wrap;
      a {
        @include centering-mode;
        width: 53px;
        height: 53px;
        font-size: 0;
        flex-shrink: 0;

        border-radius: 50%;
        border: 2px solid rgba(0, 79, 113, 0.15);

        margin: 0 10px 10px;
      }
    }
  }
}

@media screen and (max-width: 1365px) {
  .webinar {
    .about-webinar {
      grid-template-areas:
        'cnt'
        'video-block'
        'webinar-start';
      grid-template-columns: 100%;

      &__title {
        max-width: 670px;
        width: 100%;
        text-align: center;
        margin: 0 auto 40px;
      }

      &__cnt {
        text-align: center;
      }

      &__desc {
        margin-bottom: 40px;
      }

      &__video-block {
        margin-bottom: 40px;
      }
    }

    .webinar-start {
      text-align: center;

      .timer {
        margin: 0 auto 40px;
      }

      .btn {
        margin: 0 auto;
      }
    }
  }

  .speakers {
    &__title {
      text-align: center;
    }

    &__wrap {
      flex-direction: column;
      align-items: center;
    }

    .speaker {
      flex-direction: column;
      margin: 0 0 40px;

      &:last-child {
        margin: 0;
      }

      &__img-wrap {
        margin: 0 0 30px;
      }

      &__right-block {
        text-align: center;
      }
    }
  }

  .webinar-program {
    &__title {
      text-align: center;
      margin-bottom: 35px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .webinar-wrap {
    padding-top: 130px;

    .title {
      h1,
      h2 {
        font-size: 36px;
        line-height: 49px;
      }
    }
  }

  .webinar {
    .section-padding {
      padding: 50px 0;
    }
    .section-padding-b {
      padding-bottom: 50px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .webinar-program {
    &__list {
      .item {
        position: relative;
        flex-direction: column;
        align-items: unset;
        margin-left: 100px;

        &__title-block {
          min-width: unset;
        }

        &__number {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -90px;
        }

        &__text {
          max-width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .webinar-wrap {
    padding-top: 65px;
  }

  .webinar {
    .webinar-start {
      .timer {
        max-width: 330px;
        &__item {
          width: 92px;
          height: 92px;
          &:last-child {
            display: none;
          }
        }

        &__number {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .part-webinar {
    padding-bottom: 33px;
    &__title {
      margin-bottom: 8px;
    }
    &__top-block {
      text-align: center;
      margin: 20px 0 20px;
      .btn {
        margin: 0 auto 8px;
      }
    }

    &__text-subscription {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    &__subscription {
      width: max-content;
      margin: 12px auto 0;
    }
    .log-in {
      margin: 0 auto 20px;
    }
  }
}
