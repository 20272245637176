@import '../../assets/styles/variables.scss';

.news-strip {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    background: $bg-col-viking;
    color: $white;
    text-decoration: none;
    overflow: hidden;
    position: fixed;
    top: 4rem;
    left: 0;
    right: 0;
    z-index: 99;
    min-height: 42px;
    transition: all 0.3s ease;

    // Modern diagonal stripes pattern - only show in light theme
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: repeating-linear-gradient(45deg,
                transparent,
                transparent 10px,
                rgba($white, 0.03) 10px,
                rgba($white, 0.03) 20px);
        pointer-events: none;
    }

    // Gradient overlay for depth
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(90deg,
                rgba($darkCerulean, 0.1),
                transparent 20%,
                transparent 80%,
                rgba($darkCerulean, 0.1));
        pointer-events: none;
    }

    &:hover {
        background: darken($bg-col-viking, 8%);

        .news-strip__duplicate-text {
            transform: scale(1.02);
        }
    }

    [data-theme='dark'] & {
        background: $pacificBlue;
        box-shadow: inset 0 1px rgba($white, 0.05),
            inset 0 -1px rgba(0, 0, 0, 0.1);

        // Remove pattern in dark theme
        &::before {
            display: none;
        }

        &::after {
            background: linear-gradient(90deg,
                    rgba(0, 0, 0, 0.2),
                    transparent 20%,
                    transparent 80%,
                    rgba(0, 0, 0, 0.2));
        }

        &:hover {
            background: lighten($pacificBlue, 5%);
        }
    }

    &__duplicate-text {
        font-weight: 600;
        padding: 0 20px;
        white-space: nowrap;
        font-size: 13px;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        position: relative;
        transition: transform 0.3s ease;
        z-index: 1;
        text-shadow: 0 1px 2px rgba($darkCerulean, 0.2);

        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 2px;
            height: 16px;
            background: rgba($white, 0.3);
            border-radius: 1px;
        }

        &.news-strip__right-wrap {
            &::after {
                display: none;
            }
        }
    }

    &__text {
        flex: 1;
        margin: 0 16px;
        font-size: 14px;
        line-height: 1.4;
        font-weight: 500;
        text-shadow: 0 1px 2px rgba($darkCerulean, 0.2);

        p {
            margin: 0;
            padding: 0 24px;
            position: relative;

            &::before,
            &::after {
                content: '•';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                font-size: 12px;
                color: rgba($white, 0.7);
            }

            &::before {
                left: 8px;
            }

            &::after {
                right: 8px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .news-strip {
        justify-content: center;
        padding: 8px 16px;
        text-align: center;
        top: 4rem;

        &__duplicate-text {
            font-size: 12px;
            padding: 0;
            letter-spacing: 1px;

            &::after {
                display: none;
            }
        }

        // Subtle shine animation for mobile
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: -100%;
            width: 50%;
            height: 100%;
            background: linear-gradient(90deg,
                    transparent,
                    rgba($white, 0.1),
                    transparent);
            animation: shine 3s infinite;
        }
    }
}

@keyframes shine {
    0% {
        left: -100%;
    }

    20%,
    100% {
        left: 150%;
    }
}

body {
    padding-top: calc(4rem + 42px);
}