@import 'variables';
@import 'mixin';

.footer-webinar {
  padding: 90px 0;
  background-color: $bg-col-pacificBlueOpacity;

  &__title {
    text-align: center;
    margin-bottom: 44px;
    h2 {
      font-size: 48px;
      line-height: 65px;
    }
  }
}

.recording {
  margin: 0 auto;

  max-width: 1150px;
  width: 100%;

  &__fields-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 44px;
  }

  &__wrap {
    max-width: 535px;
    width: 100%;
    color: $darkCeruleanOpacity;
    margin-right: 30px;

    &:last-child {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin: 0;
    }
  }

  &__btn-wrap {
    @include centering-mode;

    .btn {
      max-width: 230px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .footer-webinar {
    padding: 55px 0;
    &__title {
      h2 {
        font-size: 28px;
        line-height: 38px;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .recording {
    &__fields-wrap {
      flex-direction: column;
      input {
        height: 62px;
      }
    }

    &__wrap {
      max-width: 100%;
      margin: 0 0 25px;
    }
  }
}
