@import 'variables';
@import 'mixin';

.article-details {
  &__container {
    max-width: 800px;
  }

  &__title {
    margin-bottom: 20px;
  }

  &__short-desc {
    margin-bottom: 20px;
  }

  &__public-date {
    margin-bottom: 48px;
  }

  &__img-wrap {
    position: relative;
    padding-top: 56.2%;
    max-width: 1015px;
    border-radius: 10px;
    overflow: hidden;

    margin-bottom: 27px;

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-height: 100%;
    }
  }
}

.article-desc {
  line-height: 34px; // Improved line height for readability
  font-size: 18px; // Adjusted for better reading on various devices

  @media screen and (max-width: 960px) {
    font-size: 16px;
    line-height: 26px;

    .content {
      font-size: 14px;
      line-height: 20px;
    }
  }

  hr {
    border: 0;
    height: 2px;
    background: #3498db8c;
    border-radius: 50%;
    margin: 40px 0;
  }

  h1,
  h2,
  h3 {
    margin: 40px 0 20px 0; // Consistent bottom margin for headings
    font-family: $OpenSansBold;
  }

  h1 {
    font-size: 46px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 40px;

    @media screen and (max-width: 960px) {
      font-size: 32px;
      line-height: 32px;
    }
  }

  p {
    margin-bottom: 34px; // Consistent bottom margin

    &.filename {
      font-family: $FantasqueSansMonoRegular;
      color: $darkCerulean;
      font-size: 18px;
      line-height: 26px;
      padding: 10px;
      // border-radius: 10px;
      letter-spacing: 0.9px;
      background-color: $bg-col-blackPearl;
      width: fit-content;
      margin-bottom: 0;
      color: $aliceBlue;
      border-top: 1px solid rgba(123, 165, 184, 0.4588235294);
      border-left: 1px solid rgba(123, 165, 184, 0.4588235294);
      border-right: 1px solid rgba(123, 165, 184, 0.4588235294);

      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  h2 {
    font-size: 36px;
    line-height: 49px;
    color: #09f;
    margin: 40px 0 10px;

    @media screen and (max-width: 960px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  h3 {
    color: rgb(68, 141, 190);
    font-size: 26px;
    line-height: 28.8px;

    @media screen and (max-width: 960px) {
      font-size: 18px;
      line-height: 32px;
      margin: 20px 0 10px;
    }
  }

  h4 {
    // color: rgb(51, 105, 141);
    font-size: 20px;
    font-weight: normal;
    line-height: 30px;
    margin: 20px 0 10px 0;

    @media screen and (max-width: 960px) {
      font-size: 16px;
      line-height: 32px;
    }
  }

  strong {
    color: $darkCerulean;
  }

  code {
    font-family: $FantasqueSansMonoRegular;
    padding: 10px;
    border-radius: 2px;
    letter-spacing: 0.9px;
    font-size: 18px;
    line-height: 26px;

    @media screen and (max-width: 960px) {
      font-size: 14px;
      line-height: 18px;

    }
  }

  ol,
  ul {
    // padding-left: 20px; // Increased indentation for clarity
    // margin-bottom: 20px; // Space after lists
    // margin-left: 20px;
    margin: 0 0 10px 10px;
    // margin: 0;
    padding: 0 0 0 21px;


    li {
      margin-bottom: 15px; // Space between list items
    }

    p {
      margin-bottom: 0;
    }


  }

  ol {

    li::marker {
      color: #3498db; // Example: Set the color of the list markers to a blue shade
      font-weight: bold; // Optional: Make the numbers bold
    }
  }

  a {
    cursor: pointer;
    color: $cloudyGreen;
    transition: all 0.5s ease-in-out;

    &:hover {
      color: $lavender;
      text-decoration: underline;
    }
  }

  img {
    border-radius: 10px;
    width: unset;
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .article-details {
    margin-top: 24px;

    &__title {
      margin-bottom: 12px;
    }

    &__short-desc {
      margin-bottom: 12px;
    }

    &__public-date {
      margin-bottom: 35px;
    }

    &__img-wrap {
      margin-bottom: 40px;
    }
  }

  .article-desc {

    h2,
    h3 {
      margin-bottom: 12px;
    }

    p {
      margin-bottom: 25px;
    }
  }
}

pre {
  margin-top: 0;
}

.hljs {
  display: block;
  overflow-x: auto;
  padding: 20px;
  margin-top: 0;
  // color: $white;
  // background-color: $bg-col-blackPearl;

  background-color: #04131e;
  color: #f6fcff;
  padding: 7px;
  border: 1px solid #7ba5b875;
  border-radius: 10px;
  // color: $aliceBlue;

  &-type {
    color: $function;
  }

  &-keyword {
    color: $keyword;
    font-style: italic;
  }

  &-string {
    color: $string;
  }

  &-title {
    color: $title;
    font-style: italic;
  }

  &-params {
    color: $params;
  }

  &-function {
    color: $function;
  }

  &-comment {
    color: $comment;
    font-style: italic;
  }

  &-built_in {
    color: $built-in;
    font-style: italic;
  }

  &-number {
    color: $number;
  }

  &-literal {
    color: $literal;
  }

  &-subst {
    color: $subst;
  }

  &-bullet {
    color: $bullet;
  }
}