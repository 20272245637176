@import 'variables';
@import 'mixin';

.img-wrap {
  &.isCurrent {
    border: 1px solid #34e590;
    border-radius: 10px;
  }
}

.section-lessons {
  &__container {
    padding: 0 2% 0;
  }

  .list-articles {
    border-bottom: 2px solid $bg-col-pacificBlueOpacity;
  }

  .list-articles:last-child {
    border-bottom: none;
  }

  &__tabs {
    display: flex;
    //flex-wrap: wrap;
    overflow-x: auto;
    justify-content: center;
    border-radius: 18px;

    a {
      text-align: center;
      color: $darkCerulean;
      margin: 15px 0px 15px 30px;

      &:hover,
      &.active {
        color: $pacificBlue;
        background: #009ee211;

        [data-theme=dark] & {
          color: #7ad7ff;
        }
      }
    }
  }

  .article {
    width: 50%;
    margin: 10px 0 10px;

    display: flex;
    flex-direction: row;
    gap: 10px;

    &__cnt {
      display: flex;
      margin: 12px;
      width: 95%;
      border-radius: 18px;
      padding: 15px;
      cursor: pointer;
    }


    &__wrap {
      margin-bottom: 0;
    }

    &__img-wrap {
      max-width: 295px;
      min-width: unset;
      height: 176px;

      margin: 0 48px 0 0;
      padding-top: unset;
      border: 2px solid rgba(0, 158, 226, 0.15);

      img {
        object-fit: fill;
      }
    }

    &__right-side {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      border-radius: 18px;
    }

    &__title {
      h3 {
        -webkit-line-clamp: 4;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__desc {
      filter: opacity(0.5);
      -webkit-line-clamp: 4;
      // height: 65px;
      max-height: 98px;
    }

    &__btn-wrap {
      max-width: 166px;
      width: 100%;

      .btn {
        height: 45px;
      }
    }

    button {
      text-align: start;
    }

    &.blocked {
      .article {
        &__desc {
          -webkit-line-clamp: 3;
          height: 64px;
        }
      }
    }

    &.unpaid {
      cursor: default;
    }
  }
}

.details-lessons {
  &__container {
    max-width: 1920px;
    margin: 0 auto;
    padding: 2rem;

    @media (max-width: 1200px) {
      padding: 1.5rem;
    }

    @media (max-width: 768px) {
      padding: 1rem;
    }
  }

  &__title {
    margin-bottom: 1.5rem;

    h2 {
      font-size: 1.75rem;
      line-height: 1.3;
      font-weight: 600;

      @media (max-width: 1200px) {
        font-size: 1.5rem;
      }
    }
  }

  .wrap {
    display: grid;
    grid-template-areas:
      "video-block"
      "btns-wrap"
      "lesson-dubbed-video-select"
      "lesson-desc"
      "test-block"
      "list-lessons";
    grid-template-columns: 1fr;
    gap: 1.5rem;
    width: 100%;
    align-items: start;

    @media (min-width: 1541px) {
      grid-template-areas:
        "video-block list-lessons"
        "btns-wrap list-lessons"
        "lesson-dubbed-video-select list-lessons"
        "lesson-desc list-lessons"
        "test-block list-lessons";
      grid-template-columns: 1fr 350px;
    }
  }

  .video {
    grid-area: video-block;
    position: relative;
    width: 100%;
    background: var(--background-secondary);
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 0;

    .video-wrapper {
      position: relative;
      padding-top: 56.25%;
      height: 0;
      overflow: hidden;
    }

    iframe,
    video,
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
      object-fit: contain;
    }

    @media (max-width: 768px) {
      margin: 0 -1rem;
      width: calc(100% + 2rem);
      border-radius: 0;
    }
  }

  .btns-wrap {
    grid-area: btns-wrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: var(--background-secondary);
    border-radius: 1rem;
    gap: 1rem;

    @media (max-width: 768px) {
      padding: 0.75rem;
      margin: 0;
      border-radius: 0.75rem;
      flex-direction: row;
    }

    a {
      display: flex;
      align-items: center;
      color: var(--text-primary);
      padding: 0.75rem 1.25rem;
      border-radius: 0.75rem;
      transition: all 0.2s ease;
      white-space: nowrap;
      flex: 1;
      justify-content: center;

      &:hover:not(.disabled) {
        background: var(--primary-color);
        color: white;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      @media (max-width: 768px) {
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
      }
    }
  }

  .lesson-dubbed-video-select {
    grid-area: lesson-dubbed-video-select;
    background: var(--background-secondary);
    padding: 1.25rem;
    border-radius: 1rem;

    @media (max-width: 768px) {
      padding: 1rem;
      border-radius: 0.75rem;
    }

    h3 {
      font-size: 1rem;
      margin-bottom: 1rem;
      font-weight: 500;
    }
  }

  .lesson-desc {
    grid-area: lesson-desc;
    background: var(--background-secondary);
    padding: 1.5rem;
    border-radius: 1rem;
    font-size: 1rem;
    line-height: 1.6;

    @media (max-width: 768px) {
      padding: 1rem;
      border-radius: 0.75rem;
      font-size: 0.9375rem;
    }

    h2 {
      font-size: 1.25rem;
      margin: 1.5rem 0 1rem;
      font-weight: 600;

      &:first-child {
        margin-top: 0;
      }
    }

    img {
      max-width: 100%;
      height: auto;
      border-radius: 0.75rem;
      margin: 1rem 0;
    }

    pre {
      margin: 1rem 0;
      padding: 1rem;
      border-radius: 0.75rem;
      background: var(--background-primary);
      font-size: 0.875rem;
      line-height: 1.5;
      overflow-x: auto;
    }
  }

  .test-block {
    grid-area: test-block;
    padding: 1.5rem;
    background: var(--background-secondary);
    border-radius: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

    @media (max-width: 768px) {
      padding: 1rem;
      border-radius: 0.75rem;
    }

    &__wrap {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin-bottom: 1.5rem;

      @media (max-width: 768px) {
        gap: 1rem;
        margin-bottom: 1rem;
      }
    }

    &__item {
      background: var(--background-primary);
      padding: 1.5rem;
      border-radius: 0.75rem;
      transition: all 0.2s ease;
      border: 1px solid transparent;

      &.valid {
        border-color: var(--success-color);
      }

      &.invalid {
        border-color: var(--error-color);
      }

      @media (max-width: 768px) {
        padding: 1rem;
      }
    }

    &__status {
      padding: 0.75rem;
      border-radius: 0.5rem;
      font-weight: 500;
      text-align: center;
      margin-bottom: 1rem;
      font-size: 0.875rem;

      &.valid {
        color: var(--success-color);
        background: var(--success-bg);
      }

      &.invalid {
        color: var(--error-color);
        background: var(--error-bg);
      }
    }

    &__title {
      margin-bottom: 1.25rem;
      color: var(--text-primary);

      .text {
        font-size: 1rem;
        line-height: 1.5;

        p {
          margin: 0;
        }

        code {
          background: var(--code-bg);
          padding: 0.25rem 0.5rem;
          border-radius: 0.25rem;
          font-size: 0.875rem;
          font-family: monospace;
        }

        pre {
          margin: 1rem 0;
          padding: 1rem;
          border-radius: 0.5rem;
          background: var(--code-bg);
          font-size: 0.875rem;
          line-height: 1.5;
          overflow-x: auto;
        }
      }
    }

    .answers {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }

    .btn-radio {
      position: relative;
      padding: 1rem 1rem 1rem 2.75rem;
      border-radius: 0.75rem;
      background: var(--background-secondary);
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: var(--background-hover);
      }

      input[type="radio"] {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
        width: 1.25rem;
        height: 1.25rem;
        cursor: pointer;

        &:checked {
          &+.btn-radio__text {
            color: var(--primary-color);
            font-weight: 500;
          }
        }
      }

      &__text {
        font-size: 0.9375rem;
        line-height: 1.5;
        color: var(--text-primary);
        transition: color 0.2s ease;

        p {
          margin: 0;
        }

        code {
          background: var(--code-bg);
          padding: 0.25rem 0.5rem;
          border-radius: 0.25rem;
          font-size: 0.875rem;
          font-family: monospace;
        }
      }
    }

    &__btn-wrap {
      display: flex;
      justify-content: center;
      gap: 1rem;

      .btn {
        min-width: 160px;
        height: 2.75rem;
        padding: 0 1.5rem;
        font-size: 0.9375rem;
        font-weight: 500;
        border-radius: 1.375rem;
        background: var(--primary-color);
        color: white;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover:not(:disabled) {
          transform: translateY(-1px);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        }

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }

        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }

  .slider-container {
    grid-area: list-lessons;
    position: relative;
    width: 100%;
    background: var(--background-secondary);
    border-radius: 1rem;
    padding: 1rem;
    height: auto;
    overflow: hidden;

    @media (min-width: 1541px) {
      min-height: 400px;
      max-height: calc(100vh - 2rem);
      display: flex;
      flex-direction: column;
    }

    @media (max-width: 768px) {
      padding: 0.75rem;
      border-radius: 0.75rem;
    }

    .slider {
      position: relative;
      padding: 0.5rem;
      width: 100%;

      @media (min-width: 1541px) {
        flex: 1;
        overflow: hidden;
        height: calc(100vh - 15rem);
      }

      .swiper {
        width: 100%;

        @media (min-width: 1541px) {
          height: 100%;
        }
      }

      .swiper-wrapper {
        @media (min-width: 1541px) {
          flex-direction: column;
        }
      }

      .swiper-slide {
        height: auto !important;
        width: 280px !important;
        max-width: 280px;

        @media (min-width: 1541px) {
          width: 100% !important;
          max-width: 100%;
        }

        @media (max-width: 768px) {
          width: 260px !important;
          max-width: 260px;
        }
      }

      .swiper-scrollbar {
        display: none;

        @media (min-width: 1541px) {
          display: block;
          position: absolute;
          right: 4px;
          top: 0;
          height: 100%;
          width: 4px;
          background: var(--background-primary);
          border-radius: 2px;
          opacity: 0;
          transition: opacity 0.3s ease;

          &-drag {
            background: var(--primary-color);
            border-radius: 2px;
          }
        }
      }

      &:hover .swiper-scrollbar {
        @media (min-width: 1541px) {
          opacity: 1;
        }
      }
    }

    .slider-navigation {
      display: flex;
      gap: 0.75rem;
      margin-top: 1rem;
      padding: 0 1rem;
      justify-content: center;

      @media (min-width: 1541px) {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        margin: 0;
        flex-direction: column;
      }
    }
  }
}

@media screen and (min-width: 1998px) {
  .section-lessons {
    &__container {
      max-width: 2560px;
      padding: 0 3%;
    }

    .list-articles {
      margin: 0px -50px;
    }

    .article {
      width: 33.3%;
    }
  }
}

@media screen and (max-width: 1679px) {
  .details-lessons {
    // Keep other styles but remove grid-template-areas as it's defined above
  }
}

@media screen and (max-width: 1540px) {
  .details-lessons {

    // Keep other styles but remove grid-template-areas as it's defined above
    .slider-container {
      position: static;
      margin: 1rem 0;
      // ... other slider styles ...
    }
  }
}

// Add intermediate breakpoint for smoother transition
@media screen and (min-width: 1280px) and (max-width: 1539px) {
  .details-lessons {
    &__container {
      padding: 1.5rem;
      max-width: 100%;
    }

    .wrap {
      gap: 1.5rem;
    }

    .video {
      margin-bottom: 1.5rem;
    }

    .btns-wrap {
      margin: 0 0 1.5rem;
      padding: 1rem;
    }

    .lesson-desc {
      padding: 1.5rem;
    }

    .slider-container {
      .slider {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        gap: 1rem;
        padding: 1rem;

        .link {
          width: 100%;
          min-width: auto;
          max-width: none;
        }
      }
    }
  }
}

// Existing breakpoint remains
@media screen and (min-width: 1254px) and (max-width: 1279px) {
  .details-lessons {
    &__container {
      padding: 1.25rem;
    }

    .wrap {
      gap: 1.25rem;
    }

    .video {
      margin-bottom: 1.25rem;
    }

    .btns-wrap {
      margin: 0 0 1.25rem;
    }

    .lesson-desc {
      padding: 1.25rem;
    }

    .slider-container {
      .slider {
        padding: 0.75rem;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 1rem;

        .link {
          width: 100%;
          min-width: auto;
          max-width: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .section-lessons {
    &__tabs {
      a {
        margin: 15px 0 15px 30px;
      }
    }
  }
}

@media screen and (max-width: 1279px) {

  .section-lessons,
  .details-lessons {
    &__tabs {
      margin-bottom: 46px;
    }

    .list-articles {
      margin: 0;
    }

    .article {
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }

  .details-lessons {
    &__title {
      margin-bottom: 30px;
    }

    .slider-container {
      .slider {
        .link {
          max-width: unset;
        }
      }
    }
  }

  .section-lessons {
    .list-articles {
      margin-bottom: 25px;
    }

    .list-articles:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 960px) {
  .section-lessons {
    &__tabs {
      margin: 30px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      list-style: none;
      text-align: center;
      gap: 5px;

      a {
        margin: 8px 0 8px 15px;
        font-size: 20px;
      }
    }
  }

  .details-lessons {
    &__container {
      padding: 1rem;
      max-width: 100%;
      margin: 0 auto;
    }

    .wrap {
      gap: 1rem;
    }

    .video {
      position: relative;
      width: 100%;
      max-width: 960px;
      margin: 0 auto 1rem;
      padding-bottom: 56.25%; // 16:9 aspect ratio
      height: 0;

      iframe,
      video,
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .details-lessons {
    &_blocked-message {
      text-align: center;
    }

    .video {
      max-width: 100%;
      margin: 0 auto 1rem;
    }
  }
}

@media screen and (max-width: 767px) {
  .section-lessons {
    &__tabs {
      font-weight: 600;
    }

    .article__desc {
      display: none;
    }

    .article {
      &__img-wrap {
        height: 100px;
      }
    }
  }

  .details-lessons {
    &__container {
      padding: 0.75rem;
    }

    .video {
      border-radius: 0.5rem;
      overflow: hidden;
      margin: 0 -0.75rem 1rem; // Negative margin to align with container
      width: calc(100% + 1.5rem); // Compensate for negative margins

      iframe,
      video,
      img {
        border-radius: 0;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: block;
    }

    .wrap {
      display: grid; // Keep grid display
      // Other styles remain
    }

    .lesson-desc {
      border: none;
      padding: 1rem;
    }

    .slider-container {
      .slider {
        flex-wrap: wrap;
        max-width: 100vw;
        margin: 0 -0.75rem 1rem;
        padding: 0.75rem;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .section-lessons {
    &__tabs {
      button {
        margin-right: 40px;
      }
    }

    .article {
      &.blocked {
        .article {
          &__wrap {
            margin-bottom: 3px;
          }

          &__title {
            margin-bottom: 2px;
          }

          &__desc {
            -webkit-line-clamp: 1;
            height: 16px;
          }

          &__btn-wrap {
            max-width: 107px;

            .btn {
              font-size: 12px;
              height: 31px;
            }
          }
        }
      }

      &__wrap {
        margin-bottom: 0;
      }

      &__img-wrap {
        max-width: 168px;
        height: 100px;
        margin-right: 15px;
      }

      &__title {
        margin-bottom: 0;
        word-break: break-word;

        h3 {
          font-size: 18px;
          line-height: 22px;
        }
      }

      &__desc {
        -webkit-line-clamp: 2;
        font-size: 12px;
        line-height: 19px;
        height: 36px;
      }
    }
  }

  .details-lessons {
    .slider-container {
      .slider {
        .link {
          margin: 0 auto;
        }
      }
    }

    .btns-wrap {
      font-size: 12px;

      .btn-arrow {
        &_left {
          margin-right: 10px;
        }

        &_right {
          margin-left: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .section-lessons {
    &__tabs {
      button {
        font-size: 13px;
      }
    }

    .article {
      &.other-pos {
        margin-bottom: 10px;
        cursor: initial;

        .article {
          &__desc {
            -webkit-line-clamp: 3;
            // separate the words by letters:
            word-break: break-all;
            height: 50px;
          }
        }
      }
    }
  }
}

// Mobile improvements
@media screen and (max-width: 768px) {
  .details-lessons {
    &__container {
      padding: 0 1rem;
    }

    &__title {
      margin-bottom: 1.5rem;

      h2 {
        font-size: 1.25rem;
        line-height: 1.4;
      }
    }

    .wrap {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      width: 100%;
    }

    .video {
      margin-bottom: 1rem;
      width: 100%;

      iframe,
      video,
      img {
        border-radius: 0.5rem;
      }
    }

    .btns-wrap {
      margin: 0.5rem 0 1rem;
      padding: 0.5rem;
      background: var(--background-secondary);
      border-radius: 0.5rem;
      font-size: 0.875rem;

      a {
        padding: 0.5rem;
        flex: 1;
        justify-content: center;
        white-space: nowrap;

        &:first-child {
          margin-right: 0.5rem;
        }
      }

      .btn-arrow {
        &_left {
          margin-right: 0.5rem;
        }

        &_right {
          margin-left: 0.5rem;
        }
      }
    }

    .lesson-dubbed-video-select {
      margin: 1rem 0;
      padding: 1rem;
      border-radius: 0.5rem;

      h3 {
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }
    }

    .lesson-desc {
      font-size: 0.9375rem;
      line-height: 1.6;

      img {
        max-width: 100%;
        height: auto;
        border-radius: 0.5rem;
      }

      pre {
        margin: 1rem 0;
        padding: 1rem;
        border-radius: 0.5rem;
        overflow-x: auto;
        font-size: 0.875rem;
        -webkit-overflow-scrolling: touch;
      }

      table {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
      }
    }
  }

  .section-lessons {
    &__tabs {
      justify-content: flex-start;
      padding: 0.5rem;
      margin: 0 -1rem;

      &::-webkit-scrollbar {
        display: none;
      }

      a {
        margin: 0.5rem;
        padding: 0.5rem 1rem;
        white-space: nowrap;
        border-radius: 1rem;
      }
    }

    .article {
      width: 100%;
      margin: 0.5rem 0;

      &__cnt {
        flex-direction: column;
        margin: 0.5rem;
        padding: 1rem;
      }

      &__img-wrap {
        max-width: 100%;
        height: 200px;
        margin: 0 0 1rem 0;

        img {
          object-fit: cover;
        }
      }

      &__right-side {
        gap: 0.75rem;
      }

      &__title h3 {
        font-size: 1rem;
        line-height: 1.4;
      }

      &__desc {
        font-size: 0.875rem;
        line-height: 1.5;
      }

      &__btn-wrap {
        max-width: 100%;

        .btn {
          height: 2.75rem;
          width: 100%;
        }
      }
    }
  }
}

// Add support for larger phones and small tablets
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .details-lessons {
    .wrap {
      grid-template-areas:
        'video-block'
        'btns-wrap'
        'lesson-dubbed-video-select'
        'lesson-desc'
        'test-block'
        'list-lessons';
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }

    .video {
      margin-bottom: 0;
    }

    .btns-wrap {
      margin-bottom: 0;
    }
  }

  .section-lessons {
    .article {
      width: 100%;

      &__img-wrap {
        max-width: 200px;
      }
    }
  }
}