@import 'variables';
@import 'mixin';

.option-list {
  display: grid;
  grid-gap: 30px;


  &__wrap {
    display: contents;
    margin-bottom: 20px;
  }

  &::-webkit-scrollbar {
    width: 2px;
  }

}

.option {
  background-color: $bg-col-white;
  border: 1px solid $bg-col-pacificBlueOpacity;
  border-radius: 42px;
  padding-inline: 20px;
  padding-block: 20px;

  [data-theme='dark'] & {
    background-color: #051826;
  }

  &__title-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }

  &__description {
    display: flex;
    font-size: 14px;
    color: $comment;
    margin: 15px 0 15px 0;
  }

  h3 {
    text-align: start;
    font-size: 24px;
    color: $glacier;
  }

  &-price {
    display: flex;
    align-items: flex-end;
    margin-top: 10px;

    &__discount {
      font-size: 29px;
      color: $darkCerulean;

      [data-theme='dark'] & {
        color: $bg-col-pacificBlue;
      }
    }

    &__val {
      white-space: nowrap;
      font-size: 18px;
      text-decoration: line-through;
      text-decoration-color: $border-col-cinnabar;
      text-decoration-thickness: 2px;
      margin-right: 10px;
    }

    &__text {
      line-height: 1;
      margin-left: 10px;
      margin-bottom: 0;
      font-size: 12px;
      color: $comment;
    }
  }

  .list {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;

    margin-top: 20px;
    border-top: 1px solid #00acff1f;
    padding-top: 10px;
    padding-inline-start: unset;

    &__item {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      svg {
        width: 20px;
        height: 10px;
        flex-shrink: 0;
        /* Prevent the icon from shrinking */
        margin: 0.4em 0 0 0;
      }

      p {
        font-size: 12px;
        margin: 0 0 0 5px;
        padding: 0;
        color: $comment;

        [data-theme='dark'] & {
          color: $white;
        }

        line-height: 1.5;
      }
    }
  }

  &-cnt__btn-wrap {
    display: flex;
    justify-content: center;
  }

  .btn__tariff {
    display: flex;
    justify-content: center;
    background: $bg-col-viking;
    color: $white;
    padding: 7px 20px;
    border-radius: 42px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    margin-top: 10px;

    [data-theme='dark'] & {
      background: $bg-col-pacificBlue;
    }

    &--active {
      background: $bg-col-cinnabar-red-opacity;

      [data-theme='dark'] & {
        background: $bg-col-cinnabar-red-opacity;
      }
    }

    &--inactive {
      color: $pacificBlue;
      background: $bg-col-pacificBlueOpacity;

      [data-theme='dark'] & {
        background: $bg-col-pacificBlueOpacity;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .option-list {
    display: flex;
    overflow-x: auto;
    grid-gap: 10px;
    /* Keep your original gap */
    scroll-snap-type: x mandatory;

    &__wrap {
      display: unset;
    }
  }

  .option {
    min-width: 70vw;
    /* Example min-width */
    scroll-snap-align: center;

    &__title-block {
      // min-height: 170px;
      min-height: 100px;
    }

    h3 {
      min-height: 50px;
    }
  }
}

@media screen and (min-width: 768px) {
  .option-list {
    grid-template-columns: repeat(2, 1fr);

    @media screen and (min-width:1600px) {
      margin: 0 auto;
    }

  }

  .option {
    .list {
      &__item {
        p {
          font-size: 13px;
        }
      }
    }
  }
}

@media screen and (min-width: 960px) {
  .option {
    h3 {
      font-size: 32px;
    }

    .list {
      &__item {
        p {
          font-size: 15px;
        }
      }
    }
  }
}