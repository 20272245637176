@import "../../assets/styles/variables.scss";

.preloader-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px
}

.spinner {
  border: 2px solid $pacificBlue;
  border-top: 2px solid $aliceBlue;
  border-radius: 50%;
  width: 50px; /* Width of the spinner */
  height: 50px; /* Height of the spinner */
  animation: spin 2s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
