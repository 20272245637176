@import '../../assets/styles/variables.scss';

.searchIcon {
  stroke: $aliceBlue;
  background: #3498db63;
  border-radius: 20px;
  height: 40px;
  width: 55px;
  padding: 7px;
}

.tick-icon {
  border: none;
  stroke: $shamrock;
  fill: $white;

  [data-theme='dark'] & {
    fill: $bg-col-blackPearl;
  }
}
