@import 'variables';
@import 'mixin';

/* base styles*/
*,
*:before,
*:after {
  box-sizing: border-box !important;
  outline: none;
}

html {
  height: 100%;
}

html.touch *:hover {
  pointer-events: none !important;
}

body {
  min-height: 100%;
  width: 100%;
  min-width: 320px;
  font-family: $OpenSansRegular, Helvetica, Arial, sans-serif;
  color: $darkCerulean;
  position: relative;
  font-size: 16px;
  line-height: 28.8px;

  max-width: 99%;
  background-color: $bg-col-aliceBlue;
  margin: 0;

  @media screen and (max-width: 640px) {
    font-size: 12px;
    line-height: 26px;
  }
}

strong {
  font-family: $OpenSansBold;
  color: $pacificBlue;
}

.list-titles {
  margin-left: 100px;

  a {
    &:hover {
      color: $darkCerulean;
      text-decoration: underline;
    }
  }

  li {
    list-style-type: decimal-leading;

    ul {
      padding-left: 45px;

      li {
        list-style-type: circle;
      }
    }
  }
}

.bulleted-list {
  margin-left: 100px;

  li {
    list-style-type: disc;

    ul {
      padding-left: 45px;

      li {
        list-style-type: circle;
      }
    }
  }
}

.cursor-outline {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;

  background-color: $bg-col-aliceBlueStrong;
  border: $border-pacificBlue;

  width: 34px;
  height: 34px;
  opacity: 0.7;
  border-radius: 100%;

  transform: translate(-50%, -50%);
  transition: width 0.1s ease-in-out, height 0.1s ease-in-out;
  will-change: top, left;
  pointer-events: none;

  &.active {
    background-color: $bg-col-pattensBlueStrong;
    border: 2px solid $border-darkCerulean;

    width: 60px;
    height: 60px;
  }
}

.copy-clipboard {
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 350px;
  width: 100%;

  margin-bottom: 20px;
  cursor: pointer;

  a {
    overflow-wrap: anywhere;
    text-decoration: underline;
    margin-right: 20px;
  }

  .btn {
    max-width: 150px;
  }
}

//begin state input
.input-wrap {
  position: relative;

  >input {
    &:focus {
      border-color: $border-pacificBlue !important;
    }
  }

  &.valid {
    >input {
      border-color: $border-shamrock;
    }
  }

  &.error {
    >input {
      border-color: $border-red;
    }

    .error {
      &__text {
        position: absolute;
        bottom: -30px;

        color: $red;
        margin-left: 15px;
      }
    }
  }
}

//end state input

.hidden {
  display: none;
}

[data-theme='dark'] {
  min-height: 100vh;
  color: $white !important;

  .btn_border-darkCerulean,
  .btn_border-pacificBlue,
  .btn_pattensBlue {
    &:hover {
      color: white !important;
    }
  }

  .notification-list .link-message__desc {
    color: $aliceBlue;
  }

  .tariff-arrow {
    stroke: none;
    fill: #019de0ff;
  }

  .section-lessons__tabs a .active {
    background: rgba(16, 157, 217, 12%);
  }

  .backgroundDarkTheme {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 100%;
    background-color: #04131e;
  }

  .btn_shamrock {
    color: #eef7f4;
  }

  .admonition {
    color: #ffffffba;
    -o-box-shadow: 0 0 10px rgba(255, 255, 255, 0.22);
    -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.22);
    -moz-box-shadow: 0 0 10px rgba(255, 255, 255, 0.22);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.22);
  }

  .btn_pattensBlue {
    background-color: #009ee236 !important;

    &:hover {
      background-color: #009ee280 !important;
    }
  }

  .details-lessons {
    .btns-wrap a {
      color: #09f;
    }

    .slider .link {
      color: #09f;
    }
  }

  .data {
    &__btn-wrap {
      .btn {
        background-color: transparent !important;
        border: 2px solid rgba(0, 79, 113, 0.15);


        &:hover {
          background-color: rgba(0, 79, 113, 0.15) !important;
          border-color: transparent;
          color: rgb(0, 79, 113) !important;
        }
      }
    }
  }

  .news-strip {
    color: $pacificBlue;
  }

  .coupons {
    &__desc-wrap {
      color: $blackPearlStrong;
    }
  }

  .title {

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $white;
    }
  }

  input {
    color: $white;
  }

  .select {
    &__val {
      color: $white;
    }

    .select-list {
      box-shadow: 0 10px 20px 0 #04131e;
      background-color: $bg-col-blackPearlStrong;
      border-radius: 20px;
      border: 1px solid $bg-col-border;
      padding-inline-start: 0;
    }
  }

  .header-authorized,
  .sidebar {
    //background-color: transparent;
    background-color: #04131ed9;
  }

  .header-authorized {
    background-image: linear-gradient(-15deg, #04131e -10%, #002336 135%);

    @media screen and (max-width: 767px) {
      background-image: linear-gradient(0deg, #04131e -150%, #002336 135%);
    }

    .burger-menu {
      background-color: $bg-col-blackPearl;
    }

    .tariff-wrap {
      .tariff {
        &__name {
          color: $white;
        }
      }
    }

    .user-nav {
      .left-wrap {
        svg {
          path {
            fill: white;
          }
        }
      }

      .user {
        &__name {
          color: $white;
        }
      }
    }
  }

  .article-desc {
    strong {
      color: #619dc7;
    }
  }

  .notification-list,
  .list-settings {
    background-color: $bg-col-blackPearl;

    &:before {
      border-bottom-color: $bg-col-blackPearl;
    }

    a {
      color: $white-opacity;
    }
  }

  .sidebar {
    a {
      color: $white;
    }
  }

  .profile-settings {
    .data {
      color: $white;
    }
  }

  .article {
    a {
      color: $white-opacity;
    }

    &__cnt {
      border: 1px solid #02141c21;
      background-color: rgba(77, 175, 224, 0.16);

      &:hover {
        border: 1px solid #5193b040;
        background-color: rgba(77, 175, 224, 0.197);
      }

      &.isCurrent {
        background-color: #4effb529;
      }

      &.watched {
        // filter: opacity(0.60);
        background-color: #0080bf0a;
      }
    }
  }

  .section-lessons__tabs {
    background: #05182617;
    border: 1px solid #05182621;
  }

  .public-date {
    color: $white-opacity;
  }

  .section-lessons {
    .list-articles {
      border-bottom: 2px solid $bg-col-pacificBlueOpacity;
    }

    &__tabs {
      a {
        color: $white;
      }
    }
  }

  .notification {
    .notification-list {
      background-color: transparent;

      .link-message {
        color: $white-opacity;

        &:hover {
          color: $white-opacity;
        }
      }
    }
  }

  .tariff {
    .option {
      background-color: $bg-col-blackPearl;
    }
  }

  .pop-up {
    &__wrap {
      background-color: $bg-col-blackPearl;
    }
  }

  .details-ether {
    .unblocked-wrap {
      color: $darkCerulean;
    }
  }

  .dashboard-main {
    .about-course {
      &__text {
        .special-container {
          border-top: none !important;
          background-color: $bg-col-pacificBlueOpacity !important;
        }

        .special-container:nth-child(odd) {
          background-color: inherit !important;
        }
      }
    }
  }
}

.tariff-arrow {
  stroke: none;
  fill: #009ee2;
}

ul,
ol {

  &.sidebar-nav__list,
  &.burger-menu,
  &.list-settings {
    list-style: none;
    margin: 0;
    padding: 0;

  }
}

p {
  margin-top: 0;
}

svg {
  path {
    transition: 0.3s;
  }
}

a {
  position: relative;

  color: $pacificBlue;
  text-decoration: none;

  transition: 0.3s;
}

.section-lessons__tabs {
  background: #0e93ec05;
  border: 1px solid #05182608;

  a {
    padding: 8px;
    border-radius: 15px;
  }
}

img {
  display: block;
  width: 100%;
  height: auto;
}

.switch {
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    position: relative;
    display: inline-block;

    width: 54px;
    height: 34px;

    border: 1px solid rgba(0, 79, 113, 0.5);
    border-radius: 40px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;

      width: 18px;
      height: 18px;

      background: rgba(0, 79, 113, 0.5);
      border-radius: 50%;

      transition: 0.3s;
    }
  }

  input[type='checkbox'] {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      +span {
        border-color: #009ee2;

        &:before {
          left: 25px;
          background-color: #009ee2;
        }
      }
    }
  }
}

.select {
  position: relative;
  display: flex;
  align-items: center;

  user-select: none;
  cursor: pointer;

  width: 100%;
  height: 60px;

  padding: 0 70px 0 50px;

  border-radius: 20px;
  border: 1px solid rgba(0, 79, 113, 0.5);

  &.active {
    .select-list {
      display: block;
    }
  }

  &:before {
    content: '';
    position: absolute;
    right: 40px;

    width: 17px;
    height: 10px;

    background-image: url('../images/other/arrow-bottom.svg');
  }

  &__val {
    color: $darkCerulean;
    margin: 0;
  }

  .select-list {
    overflow-y: auto;
    display: none;
    z-index: 99;
    box-shadow: 0 10px 20px 0 #d9d9d9;

    width: 100%;
    max-height: 348px;
    position: absolute;
    top: 50px;
    left: 0;
    border: 1px solid rgba(0, 79, 113, 0.15);
    border-radius: 20px;

    background-color: $bg-col-aliceBlue;

    li {
      padding: 10px 0;
      transition: 0.3s;
      border-bottom: 1px solid rgba(0, 79, 113, 0.5);
      justify-content: center;
      display: flex;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        color: $pacificBlue;
      }
    }
  }
}

.blocked-icon {
  @include centering-mode;
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 46px;
  height: 46px;

  border-radius: 50%;
  border: 2px solid rgba(229, 52, 52, 0.15);
}

//begin articles
.list-articles {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -50px;

  @media screen and (max-width: 1536px) {
    margin: 0 -25px;
  }

  @media screen and (max-width: 1152px) {
    margin: 0 -15px;
  }

  @media screen and (max-width: 480px) {
    justify-content: center;
    margin: 0;
  }
}

.article {
  position: relative;
  width: 33.3%;
  padding: 0 50px;
  max-height: 390px;
  margin: 30px 0;

  &__cnt {
    cursor: pointer;
  }

  @media screen and (max-width: 1536px) {
    padding: 0 25px;
  }

  @media screen and (max-width: 1152px) {
    width: 50%;
    padding: 0 15px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 0;
    margin: 0 0 30px;
  }

  &.blocked {
    display: flex;

    .article {
      &__img-wrap {
        img {
          filter: grayscale(100%) blur(5px);
        }

        &:before {
          background: none;
          mix-blend-mode: unset;
        }
      }

      &__desc {
        -webkit-line-clamp: 3;
        height: 66px;
      }
    }
  }

  &:last-child {
    @media screen and (max-width: 480px) {
      margin-bottom: 0;
    }
  }

  &__cnt {
    border-radius: 18px;
    padding: 15px;
    border: 1px solid hsl(200deg, 100%, 94%);
    background-color: rgb(235, 248, 254);


    &:hover {
      background-color: #d9f0fab3;
      border: 1px solid #e8e8e8;
    }

    &.isCurrent {
      background-color: #95f0ca4a;
    }

    &.watched {
      background-color: transparent;
    }
  }

  a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    height: auto;
    color: $glacier;
  }

  &__img-wrap {
    position: relative;
    padding-top: 54.5%;
    max-width: 400px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 26px;
    border: 2px solid rgba(0, 158, 226, 0.15);

    &.isCurrent {
      box-shadow: 0px 0px 20px 0px #34e590;
    }

    &.onDashboard {
      & img {
        filter: blur(5px);
      }

      &:before {
        position: absolute;
        z-index: 1;
        content: '';
        top: 0;
        left: 0;
        background: linear-gradient(0deg, #d9f2fb, rgb(220, 244, 255) 99.82%);

        width: 100%;
        height: 100%;
        mix-blend-mode: hard-light;
      }
    }

    @media screen and (max-width: 480px) {
      max-width: 100%;
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
    }
  }

  &__right-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  &__wrap {
    margin-bottom: 11px;
  }

  &__title {
    margin-bottom: 11px;

    @media screen and (max-width: 480px) {
      min-height: 0;
    }

    h2,
    h3,
    h4,
    h5 {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }

  &__desc {
    font-size: 14px;
    line-height: 22px;
    height: 61px;

    &--min_height {
      height: 0;
    }

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    margin: 0;
  }
}

.swiper-slide {
  .article {
    padding-left: unset;
    padding-right: unset;
  }
}

.public-date {
  display: block;
  font-size: 14px;
  color: $glacier;

  margin-top: 20px;

  &--min_height {
    margin-top: -15px;
  }
}

.status {
  font-family: $OpenSansBold;
  font-size: 14px;
  color: #af171799;
  height: max-content;

  overflow: hidden;
  display: -webkit-box;
  // -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  line-height: normal;
  padding: 10px;
  margin-top: 13px;
  background: #af171724;
  border-radius: 20px;

  @media screen and (max-width: 480px) {
    font-size: 12px;
    line-height: 19px;
  }

  &_available {
    color: $shamrock;
    background: none;
  }

  &_not-available {
    color: $cinnabar-red;
  }

  &_get-tested {
    color: $glacier;
    transition: 0.3s;

    &:hover {
      color: $pacificBlue;
    }
  }
}

//end articles

.indicator {
  flex-shrink: 0;
  display: block;

  width: 8px;
  height: 8px;

  background-color: $bg-col-shamrock;
  border-radius: 50%;
}

.flex-end {
  @include flex-end;
}

.floating-search-icon,
.floating-chat-icon {
  position: fixed;
  width: 40px;
  height: 40px;
  z-index: 10;
  bottom: 200px;
  right: 30px;

  .wrapper {
    position: absolute;
    background-color: #fff;
    fill: #fff;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $border-aliceBlueStrong;
    stroke: $darkCerulean;
  }

  .searchIcon {
    width: 35px;
    height: 25px;
    stroke: $darkCerulean;
    background-color: unset;
    padding: 0;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.floating-chat-icon {
  right: 40px;
  bottom: 120px;

  .wrapper {
    width: 60px;
    height: 60px;
  }

}

.dashboard {
  margin-top: 50px;
  padding-left: 180px;
  padding-bottom: 30px;

  @media screen and (max-width: 1023px) {
    margin-top: 24px;
    padding: 0 0 91px;
  }

  @media screen and (max-width: 480px) {
    .container {
      padding: 0 16px;
    }
  }
}

@media screen and (min-width: 1023px) {
  .toggle {
    height: 35px;
    width: 68px;
    border-radius: 20px;
    margin: auto;
    background-image: linear-gradient(#cdeeff9e, #d9f3ff);
    border: solid 1px #37a0eb1a;
    position: relative;
    cursor: pointer;
  }

  .toggle.night {
    background-image: linear-gradient(#19377085, #335f997d);
    border: solid 1px #0b172b26;
  }

  .notch {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #fffed6;
    position: absolute;
    top: 6px;
    left: 5px;
    box-shadow: 0 0 10px yellow;
    z-index: 1;
    transition: all 0.3s ease;
  }

  .night>.notch {
    background: whitesmoke;
    box-shadow: 0 0 5px whitesmoke;
    transform: translate(35px, 0);
  }
}

.main {

  //begin title
  .title {

    h1,
    h2,
    h3,
    h4 {
      font-family: $OpenSansBold;
      color: $darkCerulean;

      [data-theme=dark] & {
        color: #a6e4ff
      }

      margin: 0;
    }

    h1 {
      font-size: 64px;
      line-height: 87px;

      @media screen and (max-width: 960px) {
        font-size: 36px;
        line-height: 49px;
      }
    }

    h2 {
      font-size: 50px;
      line-height: 50px;

      @media screen and (max-width: 960px) {
        font-size: 36px;
        line-height: 49px;
      }
    }

    h3 {
      font-size: 48px;
      line-height: 65px;

      @media screen and (max-width: 960px) {
        font-size: 28px;
        line-height: 38px;
      }
    }

    h4 {
      font-size: 36px;
      line-height: 49px;
    }
  }

  //end title

  padding: 170px 0 0;

  @media screen and (max-width: 1200px) {
    padding-top: 100px;
  }
}

.not-authorization,
.after-authorization {
  // padding: 99px 0 0;

  .news-strip {
    position: fixed;
    z-index: 99;
    left: 0;

    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 38px;

    padding-right: 20px;
    padding-left: 20px;

    color: $darkCerulean;
    font-size: 18px;

    background-image: url('../images/other/news-strip.png');
    background-repeat: no-repeat;
    background-size: cover;

    &__duplicate-text {
      font-family: $OpenSansBold;
      text-transform: uppercase;
      min-width: fit-content;
      text-align: center;
    }

    &__text {
      // padding: 0 15px;
      margin-left: 20px;
      margin-right: 20px;

      max-width: 70%;

      p {
        margin: 0;
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    &__right-wrap {
      @media screen and (max-width: 1023px) {
        display: none;
      }
    }
  }
}

.banner-offset {
  // padding: 138px 0 0;
}

.container {
  position: relative;
  max-width: 1712px;
  width: 100%;

  margin: 0 auto;
  padding: 0 16px;
}

.section-padding {
  padding: 85px 0;
}

.section-padding-b {
  padding-bottom: 85px;
}

//begin input
.btn-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 10px;
  background: var(--background-color-light);
  border-radius: 15px;
  border: none;
  box-shadow: 0 2px 5px var(--shadow-color);
  gap: 10px;

  &:hover {
    background-color: var(--accent-color-hover);
    color: var(--primary-color);
  }

  input[type='radio'] {
    appearance: none; // Remove default checkbox style
    background-color: var(--background-color);
    width: 2em;
    height: 2em;
    flex-shrink: 0;
    margin: 0;
    /* Reset margin */
    border-radius: 40%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;

    &::before {
      content: '';
      width: 1em;
      height: 1em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em 0 var(--accent-color);
      border-radius: 50%;
    }

    &:checked::before {
      transform: scale(1);
    }
  }
}

input {
  width: 100%;
  height: 60px;
  padding: 0 20px 0 25px;
  border-radius: 20px;
  border: 1px solid rgba(0, 79, 113, 0.5);
  background-color: transparent;
  color: $darkCerulean;

  &.balance {
    width: 60%;
  }

  &:invalid {
    border-color: #009ee2;
  }

  &::placeholder {
    color: rgba(0, 79, 113, 0.5);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

//end input

.admonition {
  width: 100%;
  height: fit-content;
  border-radius: 15px;
  background-color: #ffffff0f;
  margin: 16px 0 24px 0;
  border-radius: 4px;
  color: $blackPearlStrong;
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.22);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.22);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.22);

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 40px;
    width: 100%;
    padding: 10px;

    .icon {
      margin-left: 10px;
      margin-right: 10px;
      @include centering-mode;
    }

    .title {
      line-height: 20px;
    }
  }

  .content {
    &-wrap {
      padding: 15px;
      min-height: 60px;
    }

    margin: unset;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: unset;
    }
  }

  &_note {
    border: 1px solid #448aff;

    .header {
      background-color: rgba(68, 138, 255, 0.1);
    }

    svg {
      path {
        fill: #448aff;
      }
    }
  }

  &_abstract {
    border: 1px solid #00b0ff;

    .header {
      background-color: rgba(0, 176, 255, 0.1);
    }

    svg {
      path {
        fill: #00b0ff;
      }
    }
  }

  &_info {
    border: 1px solid #00b8d4;

    .header {
      background-color: rgba(0, 184, 212, 0.1);
    }

    svg {
      path {
        fill: #00b8d4;
      }
    }
  }

  &_tip {
    border: 1px solid #00bfa5;

    .header {
      background-color: rgba(0, 191, 165, 0.1);
    }

    svg {
      path {
        fill: #00bfa5;
      }
    }
  }

  &_success {
    border: 1px solid #00c853;

    .header {
      background-color: rgba(0, 200, 83, 0.1);
    }

    svg {
      path {
        fill: #00c853;
      }
    }
  }

  &_question {
    border: 1px solid #64dd17;

    .header {
      background-color: rgba(100, 221, 23, 0.1);
    }

    svg {
      path {
        fill: #64dd17;
      }
    }
  }

  &_warning {
    border: 1px solid #ff9100;

    .header {
      background-color: rgba(255, 145, 0, 0.1);
    }

    svg {
      path {
        fill: #ff9100;
      }
    }
  }

  &_failure {
    border: 1px solid #ff5252;

    .header {
      background-color: rgba(255, 82, 82, 0.1);
    }

    svg {
      path {
        fill: #ff5252;
      }
    }
  }

  &_danger {
    border: 1px solid #ff1744;

    .header {
      background-color: rgba(255, 23, 68, 0.1);
    }

    svg {
      path {
        fill: #ff1744;
      }
    }
  }

  &_bug {
    border: 1px solid #f50057;

    .header {
      background-color: rgba(245, 0, 87, 0.1);
    }

    svg {
      path {
        fill: #f50057;
      }
    }
  }

  &_example {
    border: 1px solid #7c4dff;

    .header {
      background-color: rgba(124, 77, 255, 0.1);
    }

    svg {
      path {
        fill: #7c4dff;
      }
    }
  }

  &_quote {
    border: 1px solid #9e9e9e;

    .header {
      background-color: hsla(0, 0%, 62%, 0.1);
    }

    svg {
      path {
        fill: #9e9e9e;
      }
    }
  }
}

//begin btns
button {
  border: none;
  background-color: unset;
  padding: 0;
}

.github-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
  width: 48px;
  height: 48px;
  border: 2px solid rgba(0, 79, 113, 0.15);
  border-radius: 50%;

  svg {
    pointer-events: none;

    path {
      fill: #004f71;
    }
  }
}

[data-theme='dark'] {
  .github-btn {
    background-color: white;

    svg {
      path {
        fill: #0d1117;
      }
    }
  }
}

.burger {
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  width: 60px;
  height: 60px;

  border-radius: 50%;

  //transform: translateX(-16px);
  @media screen and (max-width: 960px) {
    width: 60px;
    height: 60px;
  }

  &.active {
    background: rgba(0, 158, 226, 0.15);
    //transform: translateX(0);

    .line {
      &:first-child {
        top: 8px;
        transform: rotate(45deg);
      }

      &:last-child {
        transform: rotate(-45deg);
        bottom: 9px;
      }
    }
  }

  &__wrap {
    position: relative;
    width: 40px;
    height: 20px;
  }

  .line {
    transition: 0.3s;
    display: block;
    position: absolute;

    width: 100%;
    height: 3px;

    background-color: $bg-col-darkCerulean;

    &:first-child {
      top: 0;
    }

    &:last-child {
      bottom: 0;
    }
  }
}

.btn {
  @include centering-mode;
  height: 50px;
  max-width: 185px;
  width: 100%;
  border-radius: 68px;

  padding: 0 20px;

  transition: 0.3s;

  &_pattensBlueOpacity {
    background-color: $bg-col-pacificBlueOpacity !important;
    color: $pacificBlue !important;

    &:hover {
      background-color: $bg-col-pacificBlue !important;
      color: $aliceBlue !important;
    }
  }

  &_pattensRed {
    background-color: rgba(229, 52, 52, 0.15) !important;
    color: $cinnabar-red !important;

    &:hover {
      background-color: rgba(229, 52, 52, 0.4) !important;
      color: $cinnabar-red !important;
    }
  }

  &_pattensBlue {
    background-color: $bg-col-pattensBlue !important;
    color: $pacificBlue !important;

    &:hover {
      background-color: $bg-col-pacificBlue !important;
      color: $aliceBlue !important;
    }
  }

  &_pattensBlueStrong {
    background-color: $bg-col-pattensBlueStrong;
    color: $pacificBlue !important;
    line-height: 22px;

    &:hover {
      background-color: $bg-col-pacificBlue !important;
      color: $aliceBlue !important;
    }
  }

  &_bg-viking {
    background-color: $bg-col-viking;
    color: $lightCyan;
    font-family: $OpenSansBold;

    &.subscription {
      background-color: rgba(179, 75, 75, 90%);
      margin-left: 15px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &_border-pacificBlue {
    background-color: transparent !important;
    color: $pacificBlue !important;
    border: 2px solid $bg-col-pattensBlueStrong;

    &:hover {
      background-color: $bg-col-pattensBlueStrong !important;
      border-color: transparent;
      color: $darkCerulean !important;
    }

    [data-theme=dark] & {
      background-color: rgb(85 165 199 / 41%) !important;
      color: #ffffff !important;

      &:hover {
        background-color: #01617d !important;
      }
    }
  }

  &_border-darkCerulean {
    background-color: transparent !important;
    color: $pacificBlue !important;
    border: 2px solid $bg-col-pattensBlueStrong;

    &:hover {
      background-color: $bg-col-pattensBlueStrong !important;
      border-color: transparent;
      color: $darkCerulean !important;
    }

    [data-theme=dark] & {
      background-color: rgba(0, 82, 117, 0.267) !important;
      color: white !important;

      &:hover {
        background-color: rgba(0, 82, 117, 0.8) !important;
        ;
      }
    }
  }

  &_border-pattensBlue {
    color: $darkCerulean !important;
    border: 2px solid $bg-col-pattensBlueStrong;


    [data-theme=dark] & {
      background: #009ee2 !important;
      color: white !important;
    }

    &:hover {
      color: $darkCerulean;
      border-color: transparent;
      background-color: $bg-col-pattensBlueStrong;
    }
  }

  &_shamrock {
    color: #00442e;
    border: 1px solid #29522261;
    background-color: #47b17b61;

    &.tariff-wrap__btn {
      border: unset;
      background-color: unset;
      height: 55px;
    }

    border-radius: 68px;
    width: 100%;

    &:hover {
      box-shadow: 0 0 0 0 #34e590;
      border-color: rgba(52, 229, 144, 0.35);
    }
  }

  &_border-gossamer {
    border: 2px solid $bg-col-gossamer;
    font-size: 27px;
    max-width: 265px;
    min-height: 70px;
    color: $pacificBlue;
  }
}

.btn-arrow {
  transition: 0.3s;
  display: block;
  width: 40px;
  height: 40px;
  min-width: 40px;

  border-radius: 50%;
  border: 2px solid rgba(0, 158, 226, 0.15);

  background-repeat: no-repeat;
  background-position: center;

  &_left {
    background-image: url('../images/btn/arrow-left.svg');
  }

  &_right {
    background-image: url('../images/btn/arrow-right.svg');
  }
}

//end btns

.link-telegram-notification {
  height: fit-content;
  width: 100%;
  max-width: 550px;
  margin: 50px auto;
  color: $cinnabar-red;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 600px) {
    max-width: 320px;
  }

  @media screen and (max-width: 400px) {
    font-size: 12px;
    max-width: 290px;
  }

  p {
    margin: unset;
  }

  .btn-arrow {
    background-color: rgba(229, 52, 52, 0.15);
    background-image: url('../images/btn/arrow-right-red.svg');
    color: $cinnabar-red;
    width: 30px;
    min-width: unset;
    height: 30px;
    border: none;
  }
}

.notification-list,
.list-settings {
  display: none;
  position: absolute;
  right: 0;
  max-width: 543px;
  width: 100%;

  background-color: $bg-col-aliceBlue;

  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.05);

  &.active {
    display: block;
    border-radius: 25px;
    background-color: $bg-col-white;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 999999;

    top: -40px;
    right: 66px;

    border: 20px solid transparent;
    border-bottom: 20px solid $bg-col-white;
  }

  [data-theme='dark'] & {
    background-color: $bg-col-blackPearl;

    &.active {
      background-color: $bg-col-blackPearl;
    }

    &:before {
      border-bottom-color: $bg-col-blackPearl;
    }
  }

  li {
    border-bottom: 1px solid rgba(0, 158, 226, 0.15);

    &:last-child {
      border: none;
    }
  }

  .link-message,
  a,
  button {
    display: block;
    width: 100%;
    color: $darkCerulean;
    text-align: start;

    padding: 20px 20px 20px 100px;

    &.button--red {
      color: $cinnabar-red;
    }

    &:hover {
      color: $pacificBlue;
    }
  }
}

.list-settings {
  top: 110px;

  @media screen and (max-width: 767px) {
    top: 60px;
  }

  &:before {
    right: 80px;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  a {
    &.active {
      color: $pacificBlue;
    }
  }
}

.notification-list {
  top: 101px;
  right: 58px;

  @media screen and (max-width: 1023px) {
    right: 18px;
  }

  @media screen and (max-width: 767px) {
    right: 0;
  }

  &:before {
    right: unset;
    left: 125px;

    @media screen and (max-width: 767px) {
      right: 134px;
      left: unset;
    }
  }

  li {
    display: flex;
    height: 144px;
  }

  .show-all {
    height: 86px;

    a {
      @include centering-mode;
      padding: 0;
      width: 100%;
      height: 100%;
    }
  }

  .link-message,
  a {
    font-size: 14px;
    line-height: 21px;
  }

  .link-message {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    padding: 33px 45px;

    &__left-side {
      max-width: 240px;
      width: 100%;
      margin-right: 30px;
    }

    &__desc {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      color: $darkCerulean;
    }

    &__date {
      color: $glacier;
    }

    &__img-wrap {
      position: relative;
      width: 94px;

      &:before {
        position: absolute;
        z-index: 1;
        content: '';
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: linear-gradient(89.79deg, #c0e8ff 0.17%, #dcf4ff 99.82%);
        mix-blend-mode: hard-light;
      }

      img {
        border-radius: 2px;
      }
    }
  }
}

//begin title
.title {

  h1,
  h2,
  h3,
  h4 {
    font-family: $OpenSansBold;
    color: $darkCerulean;

    margin: 0;
  }

  h1 {
    font-size: 48px;
    line-height: 65px;

    @media screen and (max-width: 960px) {
      font-size: 36px;
      line-height: 49px;
    }
  }

  h2 {
    font-size: 36px;
    line-height: 49px;

    @media screen and (max-width: 960px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  h3,
  h4 {
    font-size: 18px;
    line-height: 28.8px;
  }
}

//end title

// .pre-loader-wrap {
// z-index: 99999;
// position: fixed;
// top: 0;
// left: 0;
// width: 100vw;
// height: 100vh;
// backdrop-filter: blur(1.6666666667rem);
// }

.pre-loader {
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -40px;

  width: 80px;
  height: 80px;

  border-radius: 50%;
  border: 6px solid $border-aliceBlueStrong;
  border-left: 6px solid #009ee2;

  animation: spinner 1.5s infinite linear;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.pop-up {
  // display: none;
  z-index: 999999;
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  bottom: unset;
  width: 100%;
  height: 0%;
  transition: all 500ms ease-in-out;

  backdrop-filter: blur(1.6666666667rem);

  &__wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    max-width: 520px;
    width: 100%;

    box-shadow: 0 5px 50px rgba(0, 0, 0, 0.05);
    background-color: $bg-col-aliceBlue;

    padding: 80px;

    @media screen and (max-width: 600px) {
      max-width: 280px;
      padding: 25px;
    }
  }

  &.active {
    top: 0;
    bottom: 0;
    height: 100%;
    display: block;
  }

  &__title {
    margin-bottom: 30px;
  }

  .entry-field {
    margin-bottom: 40px;
  }

  .input-wrap {
    display: flex;
    align-items: center;
    max-width: 320px;

    &__currency {
      margin-left: 40px;
    }
  }

  .btns-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      &:first-child {
        transition: 0.3s;
        color: $border-pacificBlue;
        margin-right: 30px;

        &:hover {
          color: $border-darkCerulean;
        }
      }
    }
  }
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: default;
}

.modal {
  z-index: 999999;
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 500ms ease-in-out;
  backdrop-filter: blur(1.6666666667rem);

  &.entered {
    top: 0;
  }

  &.exiting {
    top: -100%;
  }

  &.exited {
    top: -100%;
  }

  &.order-bot {
    .modal {
      &__wrap {
        max-width: 550px;
        width: 100%;

        @media screen and (max-width: 600px) {
          max-width: 280px;
        }
      }
    }
  }

  &.log-in {
    .modal {
      &__wrap {
        min-width: 332px; // max-width: 332px;
        max-width: 450px;
        width: 90%;

        @media screen and (max-height: 700px) {
          top: 5%;
        }
      }

      &__close {
        color: $red;
        position: absolute;
        right: 10px;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          transform: scale(1.1);
        }

        @media screen and (min-width: 600px) {
          display: none
        }
      }
    }

    &__cnt {
      display: flex;
      justify-content: center;

    }
  }

  //       a {
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;

  //         font-size: 0;
  //         width: 48px;
  //         height: 48px;
  //         border: 2px solid rgba(0, 79, 113, 0.15);
  //         border-radius: 50%;
  //         margin: 0 5px;
  //         background: white;
  //       }
  //     }
  //   }
  // }

  &.buy-lesson {
    .modal {
      &__title {
        h2 {
          font-size: 24px;
          line-height: 30px;
        }
      }

      &__wrap {
        max-width: 550px;
        width: 100%;

        @media screen and (max-width: 600px) {
          max-width: 280px;
        }
      }

      &__cnt {
        a {
          overflow-wrap: anywhere;
        }
      }
    }
  }

  &.popup-pay {
    .modal {
      &__title {
        h2 {
          color: $cinnabar-red;
        }
      }

      &__wrap {
        max-width: 550px;
        width: 100%;

        @media screen and (max-width: 600px) {
          max-width: 280px;
        }
      }

      &__cnt {
        a {
          overflow-wrap: anywhere;
        }
      }
    }
  }

  &__wrap {
    position: absolute;
    // top: 50%;
    left: 50%;
    top: 10%;
    /* or any value that works for you */
    transform: translate(-50%, 0);

    background-color: $bg-col-white;
    border-radius: 50px;
    padding: 30px;

    @media screen and (max-width: 600px) {
      //max-width: 280px !important;
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 15px;

    h2 {
      font-size: 24px !important;
      line-height: 34px !important;

      @media screen and (max-width: 600px) {
        font-size: 18px !important;
        line-height: 28px !important;
      }
    }
  }
}

.footer_placeholder {
  height: 200px;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  overflow: hidden;
}

.pointer-events {
  &_none {
    pointer-events: none;
  }
}

.toast-copy-notification {
  color: $white;
  line-height: 20px;
  background-color: $bg-col-viking;
  padding: 0 20px;
  text-align: center;

  .toast-label {
    @include centering-mode;
    width: 100%;
  }
}

.custom-toast-notification {
  padding: 0 20px;
  text-align: center;
  line-height: 20px;

  &_success {
    color: $white;
    background-color: $bg-col-viking;
  }

  &_error {
    color: $white;
    background-color: $bg-col-cinnabar-red;
  }

  .toast-label {
    @include centering-mode;
    width: 100%;
  }
}

.new-badge {
  background: linear-gradient(90deg, #00b0ff, #009ee2);
  border: 1px solid #009ee2;
  /* Add a border to the badge */
  border-radius: 60px;
  /* Round the corners of the badge */
  color: white;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
  position: absolute;
  right: 0;
  top: 0;
  text-transform: uppercase;
  z-index: 99;
}

code,
pre {
  font-family: $FantasqueSansMonoRegular;

  // background: #f5f2f0;
  [data-theme=dark] & {
    background-color: #051826;
  }

  padding: 0;

}



.langing-levels-section {
  li {
    list-style: none;
  }
}


li {
  padding-left: 5px;
}

/*fonts*/

@mixin font-face($style-name,
  $file: $style-name,
  $weight: normal,
  $style: normal) {
  $filepath: '../fonts/' + $file;

  @font-face {
    font-family: quote($style-name);
    src: url($filepath + '.woff') format('woff'),
      url($filepath + '.ttf') format('truetype'),
      url($filepath + '.svg#' + $style-name + '') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

//  example->    @include font-face('PFDinTextPro', 'PFDinTextPro-Bold', 700, italic);
@include font-face('OpenSansRegular', 'OpenSans-Regular', 400);
@include font-face('OpenSansBold', 'OpenSans-Bold', 700);
@include font-face('FantasqueSansMonoRegular',
  'FantasqueSansMono-Regular',
  400);
@include font-face('FantasqueSansMonoItalic', 'FantasqueSansMono-Italic', 400);

/*/fonts*/

/* /base styles */

/*End Layout*/