@import "variables";

:root {
  --color-online: #34E590;
  --color-info: #E63434;

  --color-arrow: #009EE2;
  --color-transparent: transparent;
}

.light {
  --color-light: #f7fcff;

  --border-btn-header: #D9F8EE;

  --color-white: #fff;
  --color-element: #004F71;

  --color-border: rgba(213, 240, 252, 1);

  --color-table-light: #7BA5B8;
  --color-table-back: white;
  --color-table-border: #d1eefb;

  --color-progress-light: #ECEFF1;

  --color-border-light: #D1EEFB;

  --color-total-text: #7BA5B8;

  --progress-one: #72CBF3;
  --progress-two: #14A8EB;
  --progress-three: #0C658D;
  --progress-four: #08435E;

  --color-link: #004F71;
  --color-dash: white;
}

.dark {
  --color-light: linear-gradient(152.97deg, #389289 -20.94%, #0C182D 45.73%);

  --border-btn-header: #21665A;

  --color-element: #fff;
  --color-white: #004F71;

  --color-border: rgba(0, 158, 226, 0.15);

  --color-table-light: #858B94;
  --color-table-back: linear-gradient(0deg, rgba(6, 41, 65, 0.4), rgba(6, 41, 65, 0.4));
  --color-table-border: #009ee226;

  --color-progress-light: #081325;

  --color-border-light: rgba(0, 158, 226, 0.4);

  --progress-four: #72CBF3;
  --progress-three: #14A8EB;
  --progress-two: #0C658D;
  --progress-one: #08435E;

  --color-total-text: #858B94;

  --color-dash: #050c16;
  --color-link: white;
}

@media screen and (max-width: 768px) {
  .dark {
    --color-link: #004F71 !important;
    --color-light: linear-gradient(176.45deg, #389289 6.72%, #0C182D 43.51%);
  }
}

body {
  margin: 0;
  background: var(--color-light);
  font-family: $OpenSansRegular;
  font-weight: normal;
  font-style: normal;
}

body::-webkit-scrollbar {
  width: 0;
}