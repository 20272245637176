@import '../../assets/styles/variables.scss';

.tariff-info {
    display: flex;
    flex-direction: column;
    border-radius: 20px;

    [data-theme=dark] & {
        background-color: #051826;
    }

    border: 1px solid rgba(0, 158, 226, 0.15);

    margin-bottom: 20px;

    &__item {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: center;
        padding: 15px;

        border-bottom: 1px solid rgba(0, 158, 226, 0.15);

        &.header {
            color: $pacificBlue;
            font-family: "OpenSansBold";
        }

        &:last-child {
            border-bottom: none;
        }

        &__title {
            font-size: 20px;

            &.cancel-btn {
                grid-column: 1 / -1;
            }

            .resume-btn {
                color: $pacificBlue;
                font-size: 16px;
            }

            .cancel-btn {
                color: $red;
                font-size: 16px;
            }
        }

        &__value {
            font-size: 18px;
        }
    }
}

.free-access-notification {
    background: linear-gradient(135deg, #4CAF50 0%, #45a049 100%);
    color: white;
    padding: 20px;
    border-radius: 12px;
    margin: 20px auto;
    max-width: 800px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .notification-content {
        text-align: center;

        h3 {
            font-size: 24px;
            margin-bottom: 12px;
            font-weight: 600;
        }

        p {
            font-size: 16px;
            margin-bottom: 16px;
            opacity: 0.9;
        }

        .telegram-link {
            display: inline-flex;
            align-items: center;
            background: rgba(255, 255, 255, 0.2);
            padding: 8px 16px;
            border-radius: 8px;
            color: white;
            text-decoration: none;
            transition: background 0.3s ease;
            font-weight: 500;

            &:hover {
                background: rgba(255, 255, 255, 0.3);
            }

            .telegram-icon {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }
    }
}

.tariffs-page__content {
    position: relative;

    .tariffs-disabled-notice {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        padding: 20px 40px;
        border-radius: 12px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
        z-index: 20;
        text-align: center;

        [data-theme=dark] & {
            background: #1e1e2d;
            color: white;
        }

        h3 {
            font-size: 20px;
            margin: 0;
            white-space: nowrap;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background:
            repeating-linear-gradient(45deg,
                rgba(128, 128, 128, 0.1),
                rgba(128, 128, 128, 0.1) 10px,
                rgba(128, 128, 128, 0.2) 10px,
                rgba(128, 128, 128, 0.2) 20px);
        backdrop-filter: grayscale(100%) blur(1px);
        z-index: 10;
        border-radius: 12px;
    }

    .billing-cycle-switch,
    .option-list {
        opacity: 0.5;
        pointer-events: none;
    }
}

@media screen and (max-width: 768px) {
    .tariff-info {
        &__item {
            // grid-template-columns: 1fr;
            // grid-template-rows: repeat(2, minmax(0, 1fr));
            // padding: 15px 15px 0 15px;

            &.header {
                grid-template-rows: 1fr;
            }

            &__title {
                font-size: 16px;

                .cancel-btn {
                    font-size: 14px;
                }
            }

            &__value {
                font-size: 14px;
            }
        }
    }

    .tariffs-options {
        .tariffs-disabled-notice {
            padding: 15px 25px;
            width: 90%;

            h3 {
                font-size: 16px;
                white-space: normal;
            }
        }
    }
}