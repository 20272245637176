@import 'variables';
@import 'mixin';

[data-theme='dark'] {
  .header-authorized {
    .burger-menu {
      &__link {
        color: #c0e8ff;
      }
    }
  }
}


.header-not-authorized {
  [data-theme=dark] & {
    background-color: #051826;
  }
}

.header-authorized {
  position: fixed;
  z-index: 999;
  width: 100%;
  border-bottom: 1px solid rgba(0, 158, 226, 0.15);
  background-color: $bg-col-aliceBlue;



  left: 0;

  &__container {
    position: relative;
    display: flex;
    align-items: center;

    max-width: 1756px;

    padding: 13px 16px;
  }

  .burger-menu {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $bg-col-aliceBlue;

    &.is-open {
      display: block;
    }

    li {
      border-bottom: 1px solid rgba(0, 158, 226, 0.15);

      &:first-child {
        padding: 33px 16px 45px;
      }
    }

    &__link {
      padding: 17px 16px 15px;
      display: block;
      width: 100%;

      text-align: start;
      color: $darkCerulean;

      @media screen and (max-width: 480px) {
        font-size: 20px;
      }

      &.active {
        color: $pacificBlue;
      }
    }
  }

  .tariff-wrap {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    margin-right: 12.6%;

    &__tariff {
      margin-right: 34px;
    }

    .tariff {
      display: flex;
      align-items: center;

      &__indicator {
        margin-right: 22px;
        display: flex;
      }

      &__name {
        margin: 0;
        font-size: 14px;
        color: $darkCerulean;
      }
    }

    &__btn {
      max-width: 90px;
      width: 100%;
      height: 55px;
    }
  }

  .user-nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    max-width: 500px;
    width: 100%;

    .left-wrap {
      button {
        position: relative;
        display: flex;

        .indicator {
          position: absolute;
          top: -2px;
          right: -2px;
          background-color: $bg-col-cinnabar-red;
        }
      }
    }
  }

  .user {
    display: flex;
    align-items: center;

    &__name {
      font-size: 14px;
      color: $darkCerulean;
      margin: 0 51px 0 0;
      overflow: hidden;
      /* Hide text that goes beyond the container */
      text-overflow: ellipsis;
      /* Add an ellipsis to the end of the hidden text */
      max-width: 120px;
      /* Set a maximum width for the name container */
    }

    &__img-wrap {
      cursor: pointer;
      @include centering-mode;
      flex-shrink: 0;

      width: 73px;
      height: 73px;

      img {
        width: 73px;
        height: 73px;
        border-radius: 50%;
      }
    }

    &__btn-arrow {
      margin-left: 8px;
    }
  }

  .burger {
    display: none;
    width: 30px;
    height: 30px;
    transform: unset;
    background-color: transparent;

    &__wrap {
      width: 30px;
    }
  }
}

@keyframes ringing {

  0%,
  100% {
    transform: rotate(0);
  }

  8.33% {
    transform: rotate(-25deg);
  }

  16.67% {
    transform: rotate(25deg);
  }

  25% {
    transform: rotate(-12.5deg);
  }

  30% {
    transform: rotate(0);
  }
}

.flashing {
  animation: ringing 4s cubic-bezier(0.37, 0.07, 0.19, 1) infinite;
}


@media screen and (max-width: 1023px) {
  .header-authorized {
    .container {
      // max-width: 100%;
      padding: 13px 32px;
    }

    .tariff-wrap {
      justify-content: flex-start;
      margin-right: 30px;

      .tariff {
        &__name {
          font-size: 12px;
        }
      }

      &__btn {
        font-size: 16px;
      }
    }

    .user-nav {
      max-width: 400px;
    }

    .user {
      &__name {
        margin-right: 30px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .header-authorized {
    .tariff-wrap {
      &__tariff {
        margin-right: 20px;
      }

      &__btn {
        max-width: 130px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .header-authorized {
    .burger-menu {
      .user {
        display: flex;
        margin-right: 40px;

        &__img-wrap {
          margin-right: 20px;

          img {
            width: 57px;
            height: 57px;
          }
        }

        &__name {
          display: block;
          font-size: 16px;
          margin-right: 0;
        }
      }

      .tariff-wrap {
        display: flex;
        flex-direction: column;
        margin-right: 10px;

        &__tariff {
          margin-right: 0;
        }

        .tariff {
          &__name {
            font-size: 16px;
          }

          &__indicator {
            margin-right: 9px;
          }
        }

        &__btn {
          display: flex;
          font-size: 16px;

          max-width: 100px;
          height: 34px;
        }
      }
    }

    .user-nav {
      max-width: 160px;
    }

    .tariff-wrap {
      &__btn {
        display: none;
      }

      .tariff {
        &__indicator {
          margin-top: 9px;
          margin-right: 10px;
        }
      }
    }

    .user {

      &__name,
      &__btn-arrow {
        display: none;
      }

      &__img-wrap {
        margin: 0 15px;
      }
    }

    .burger {
      display: block;
    }
  }
}

@media screen and (max-width: 360px) {
  .header-authorized {
    .burger-menu {
      .tariff-wrap {
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 450px) {

  .header-authorized {
    .tariff-wrap {
      //border: solid 1px #1f475a7a;
      border-radius: 35px;
      margin-right: 10px;
      padding: 0 10px;
      //background-color: #50529333;
    }
  }
}

.tariff-wrap__link {
  display: flex;
  align-items: center;
  //justify-content: center;
}