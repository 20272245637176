@import 'variables';
@import 'mixin';

.footer {
  border-top: 1px solid rgba(0, 158, 226, 0.15);

  &__container {
    max-width: 1332px;
  }

  a {
    &:before {
      transition: 0.6s;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;

      width: 0;
    }

    &:hover {
      &:before {
        width: 100%;
        height: 2px;

        background-color: $bg-col-pacificBlue;
      }
    }
  }

  &__mail {
    display: block;
    width: fit-content;

    font-size: 36px;
    line-height: 49px;
    font-family: $OpenSansBold;

    margin-bottom: 45px;
  }

  .links {
    display: flex;
    justify-content: space-between;

    a {
      display: inline-block;
      margin-bottom: 57px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .support {
      a {
        display: block;
        width: fit-content;
        color: $darkCerulean;

        [data-theme='dark'] & {
          color: #7ba5b8;
        }

        &:hover {
          &:before {
            background-color: $bg-col-darkCerulean;
          }
        }
      }
    }

    .social-networks {
      a {
        &:first-child {
          margin-right: 106px;
        }

        &:last-child {
          margin-right: 76px;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .footer {
    &__mail {
      font-size: 20px;
      line-height: 24px;

      margin: 0 auto 25px;
    }

    .links {
      flex-direction: column;

      .support {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 25px;

        a {
          margin-bottom: 12px;
        }
      }

      .social-networks {
        margin: 0 auto;

        a {
          &:last-child {
            margin-right: 30px;
          }
        }

        &__bottom {
          a {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}