@import 'variables';
@import 'mixin';

.profile-settings {
  &__container {
    padding: 0 6.7%;
  }

  .photo-setting {
    display: flex;
    align-items: center;
    justify-content: space-between;

    max-width: 535px;
    width: 100%;

    margin-bottom: 44px;

    &__img-wrap {
      width: 183px;
      margin-right: 30px;

      img {
        border-radius: 50%;
      }
    }

    .btn {
      max-width: 220px;
      width: 100%;
    }
  }

  .data {
    max-width: 800px;
    width: 100%;
    color: rgba(0, 79, 113, 0.5);

    margin-bottom: 68px;

    &__btn-wrap {
      display: flex;
      justify-content: center;

      .btn {
        max-width: 170px;
        height: 60px;

        @media screen and (max-width: 767px) {
          height: 35px;
          max-width: 120px;
          margin: 15px 0;
        }
      }
    }

    &__wrap {
      display: flex;
      justify-content: space-between;
      width: inherit;
      margin-bottom: 20px;
    }

    // .email {
    //   margin-top: 47px;
    // }

    .select {
      &__val {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }
    }

    &__item {
      max-width: 535px;
      width: 100%;
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .date-birth {
    margin-bottom: 40px;

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
    }

    .wrap {
      display: flex;
      align-items: center;
    }

    &__wrap {
      display: flex;
      justify-content: space-between;
      max-width: 585px;
      width: 100%;
      margin-right: 75px;
    }

    &__item {
      width: 100%;
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }

    &__number {
      max-width: 150px;
    }

    &__mounth {
      max-width: 200px;
    }

    &__year {
      max-width: 170px;
    }
  }

  .site-setting {
    &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;

      max-width: 580px;
      width: 100%;
      margin-bottom: 22px;

      @media screen and (max-width: 767px) {
        margin-bottom: 15px;
      }

      button {
        max-width: fit-content;
        padding: 0 10px;

        @media screen and (max-width: 480px) {
          height: 40px;
        }
      }
    }

    &__value {
      color: #009ee2;
      margin-left: 10px;
    }

    .telegram-login {
      max-width: 200px;
      display: flex;
    }
  }
}

@media screen and (max-width: 1279px) {
  .profile-settings {
    .date-birth {
      &__wrap {
        margin-bottom: 60px;
      }

      .wrap {
        flex-direction: column;
        align-items: unset;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .profile-settings {
    .data {
      input {
        height: 35px;
      }

      margin-bottom: 10px;
    }

    .select {
      padding: 0 50px 0 20px;
      height: 35px;

      &:before {
        right: 20px;
      }
    }

    .data {
      &__wrap {
        flex-direction: column;
        margin-bottom: 10px;
      }

      &__item {
        max-width: 100%;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      // .email {
      //   margin-top: 44px;
      // }
    }

    .date-birth {
      &__item {
        margin-right: 11px;
      }

      &__number {
        max-width: 100px;
      }

      &__mounth {
        max-width: 160px;
      }

      &__year {
        max-width: 120px;
      }

      &__wrap {
        max-width: 480px;
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .profile-settings {
    .date-birth {
      .select {
        padding: 0 25px 0 20px;

        &:before {
          right: 10px;
        }
      }

      &__number {
        max-width: 90px;
      }

      &__mounth {
        //padding: 0 25px 0 15px !important;
      }

      &__year {
        max-width: 100px;
      }

      &__wrap {
        max-width: 480px;
      }

      .btn {
        margin: 0 auto;
      }
    }
  }
}

@media screen and (max-width: 359px) {
  .profile-settings {
    .date-birth {
      .select {
        padding: 0 24px 0 12px;
      }

      &__number {
        max-width: 70px;
      }

      &__mounth {
        &:before {
          right: 5px !important;
        }
      }

      &__year {
        max-width: 85px;
      }
    }
  }
}