@import 'variables';
@import 'mixin';

.dashboard-main {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__wrap {
    .title {
      max-width: 1400px;
      margin-bottom: 45px;
    }
  }

  &__container {
    padding: 0 6.7%;
  }

  &__slider {
    width: 100%;
    margin-bottom: 50px;

    .title {
      margin-bottom: unset;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  .news-list {
    margin-bottom: 80px;

    .article {
      &__img-wrap {
        max-width: 425px;

        &:before {
          display: none;
        }
      }
    }
  }

  .list {
    display: flex;
    max-width: 1318px;
    width: 100%;
    margin: 0 -20px;

    .article {
      width: 100%;
      padding: 0 20px;
      margin: 0;

      &:last-child {
        margin-right: 0;
      }

      &__title {
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        h3 {
          font-size: 20px;
          line-height: 32px;
          color: $pacificBlue;
          -webkit-line-clamp: 4;
          width: 100%;
        }
      }

      &__img-wrap {
        max-width: 400px;
        height: 239px;
        padding-top: unset;
        margin-bottom: unset;

        &:before {
          backdrop-filter: blur(3px);
        }

        img {
          object-fit: fill;
        }
      }

      .status {
        margin-top: 26px;
      }
    }
  }

  .about-course {
    &__title {
      margin-bottom: 25px;
    }

    &__text {
      p {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .special-container {
        border: none !important;
        border-top: 2px solid $border-aliceBlueStrong !important;
        padding: 5px 10px !important;
        background: none !important;
        background-color: $bg-col-aliceBlueStrong !important;
      }

      .special-container:nth-child(odd) {
        background-color: $bg-col-aliceBlue !important;
      }
    }
  }

  .swiper-lessons {
    padding: 0 60px;

    .article {
      width: unset;
      margin: unset;

      &__title {
        z-index: 1;
        position: absolute;
        top: 27%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 0 40px;
        text-align: center;

        h3 {
          font-size: 18px;
          line-height: 30px;
          color: $pacificBlue;
          -webkit-line-clamp: 3;
          width: 100%;
        }
      }
    }

    .swiper-button {
      &-prev {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid rgba(0, 158, 226, 0.15);
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../images/btn/arrow-left.svg');

        &::after {
          content: none;
        }
      }

      &-next {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid rgba(0, 158, 226, 0.15);
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../images/btn/arrow-right.svg');

        &::after {
          content: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1536px) {
  .dashboard-main {
    .news-list {
      margin: 35px -25px;

      .article {
        padding: 0 25px;
      }
    }
  }
}

@media screen and (max-width: 1152px) {
  .dashboard-main {
    .news-list {
      .article {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .dashboard-main {
    &__wrap {
      .title {
        margin-bottom: 35px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .dashboard-main {
    .swiper-lessons {
      padding: 0;
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}

@media screen and (max-width: 599px) {
  .dashboard-main {
    .swiper-lessons {
      padding: 0 40px;
    }
  }
}

@media screen and (max-width: 480px) {
  .dashboard-main {
    .title {
      .btn {
        display: none;
      }
    }

    .news-list {
      .article {
        width: 100%;
      }
    }
  }
}