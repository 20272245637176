@import 'variables';
@import 'mixin';

.wallet {
  &__container {
    padding: 0 6.7%;
  }

  &__title {
    margin-bottom: 40px;
  }

  &__balance {
    margin-bottom: 40px;
  }

  &__data-item {
    margin-bottom: 40px;
  }

  .balance {
    font-family: $OpenSansBold;
    font-size: 24px;
    line-height: 33px;
  }

  .input-wrap {
    display: flex;
    align-items: center;
    max-width: 400px;
    width: 100%;

    &__currency {
      margin-left: 20px;
    }
  }
}
