@import 'variables';
@import 'mixin';

.news {
  margin-top: 73px;
  &__container {
    padding: 0 5.32%;
  }

  .article {
    &__img-wrap {
      &:before {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1365px) {
  .news {
    margin-top: 48px;
  }
}

@media screen and (max-width: 480px) {
  .news {
    margin-top: 24px;
  }
}
